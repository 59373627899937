import React, { ReactNode, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import ImpersonationLabel from '../../../ImpersonationLabel/ImpersonationLabel';
import Footer from '../Footer/Footer';
import { NavMenu } from '../NavMenu/NavMenu';
import './../PageLayout/PageLayout.scss';

interface PageLayoutProps {
  children?: ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }: PageLayoutProps) => {
  const [user, setUser] = useState<User_BasicDetails_ViewModel | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const userViewModel = await AuthorizeService.populateActiveUser();
      setUser(userViewModel);
    };

    loadData();
  }, []);

  return (
    <div>
      {user ? <ImpersonationLabel user={user} /> : <></>}

      <NavMenu />

      <div className="outletWrapper">
        <Outlet />
      </div>

      {children}

      <Footer />
    </div>
  );
};
