import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../../constants';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import { ChooseStates } from '../../../../Advisor/ChooseStates';
import AuthorizeService from '../../../../api-authorization/AuthorizeService';
import LoadingDots from '../../../../ui/LoadingAnimations/LoadingDots/LoadingDots';
import LoadingSpinner from '../../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import AdvisorEditRate from '../AdvisorEditRate/AdvisorEditRate';

export const AdvisorAccountProfile = () => {
  const [user, setUser] = useState<User_BasicDetails_ViewModel | null>(null);
  const [advisor, setAdvisor] = useState<Advisor_BasicDetails_ViewModel | null>(null);
  const [advisorBeingEdited, setAdvisorBeingEdited] = useState<Advisor_EditAdvisor_EditModel | null>();
  const [redirectToCalendar] = useState<boolean>(false);
  const [saveShowReviewsLoading, setSaveShowReviewsLoading] = useState<boolean>(false);
  const [showSavedMessage, setShowSavedMessage] = useState<boolean>(false);
  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });
  const [shortBioLines, setShortBioLines] = useState<string[] | null>(null);
  const [bioBulletsAreSaving, setBioBulletsAreSaving] = useState<boolean>(false);
  const [rightCapitalLinksAreSaving, setRightCapitalLinksAreSaving] = useState<boolean>(false);

  const numShortBioLines = 3;

  useEffect(() => {
    const loadData = async () => {
      const userViewModel = await AuthorizeService.populateActiveUser();
      setUser(userViewModel);

      const advisor = await AdvisorsApiService.getAdvisorDetailsByIdOrSlug(userViewModel.slug, false, false, false);
      setAdvisor(advisor);

      setAdvisorBeingEdited({
        ...advisor!
      });

      const shortBio = userViewModel.bio || '\n\n';
      let array = shortBio.split('\n');
      // Ensure array has exactly three elements
      if (array.length < numShortBioLines) {
        // If array has less than three elements, pad with empty strings until it has three elements
        while (array.length < numShortBioLines) {
          array.push('');
        }
      } else if (array.length > numShortBioLines) {
        // If array has more than three elements, truncate it to have only the first three elements
        array = array.slice(0, numShortBioLines);
      }

      setShortBioLines(array);
    };

    loadData();
  }, []);

  const onShortBioChanged = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let newVal = (event.target.value || '').trimStart();

    if (newVal.length > shortBioMaxLength) {
      newVal = newVal.substring(0, shortBioMaxLength);
    }

    const updatedBioLines = [...shortBioLines!];
    updatedBioLines[index] = newVal;
    setShortBioLines(updatedBioLines);
  };

  const onRightCapitalInputChanged = (e: React.ChangeEvent<HTMLInputElement>, propertyName: keyof Advisor_EditAdvisor_EditModel) => {
    let newVal = (e.target.value || '').trimStart();

    if (newVal.length > rightCapitalFieldsMaxLength) {
      newVal = newVal.substring(0, rightCapitalFieldsMaxLength);
    }

    setAdvisorBeingEdited((v) => {
      return {
        ...v!,
        [propertyName]: e.target.value
      };
    });
  };

  const onSaveShortBio = async (event: React.FormEvent) => {
    event.preventDefault();
    hideError();

    const newBio = shortBioLines!.join('\n');

    setAdvisorBeingEdited((v) => {
      return {
        ...v!,
        bio: newBio
      };
    });

    setBioBulletsAreSaving(true);

    try {
      await AdvisorsApiService.updateAdvisor({
        ...advisorBeingEdited!,
        bio: newBio
      });

      setShowSavedMessage(true);
      setTimeout(() => setShowSavedMessage(false), 3000);
    } catch (error) {
      showError(error as string);
      console.error('Unable to add item.', error);
    } finally {
      setBioBulletsAreSaving(false);
    }

    return false;
  };

  const onSaveShowReviews = async (event: React.FormEvent) => {
    event.preventDefault();
    hideError();

    setSaveShowReviewsLoading(true);

    try {
      await AdvisorsApiService.updateAdvisor({
        ...advisorBeingEdited!
      });

      setShowSavedMessage(true);
      setTimeout(() => setShowSavedMessage(false), 3000);
    } catch (error) {
      showError(error as string);
      console.error('Unable to add item.', error);
    } finally {
      setSaveShowReviewsLoading(false);
    }

    return false;
  };

  const saveRightCapitalLinks = async () => {
    try {
      setRightCapitalLinksAreSaving(true);

      await AdvisorsApiService.updateAdvisorFinancialPlanningLinks(advisorBeingEdited!);

      setShowSavedMessage(true);
      setTimeout(() => setShowSavedMessage(false), 3000);
    } catch (error) {
      showError(error as string);
      setSaveShowReviewsLoading(false);
      console.error('Unable to add item.', error);
    } finally {
      setRightCapitalLinksAreSaving(false);
    }
  };

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function hideError() {
    setErrorSection({ show: false, text: null });
  }

  if (redirectToCalendar) {
    window.location.href = advisor!.calendarLink;
    return <LoadingSpinner message="Looking up calendar..." />;
  }

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Advisor Profile</title>
      </Helmet>
      {showSavedMessage && <Alert className="saved-alert">Your preference has been saved!</Alert>}
      <Row>
        <Col className="advisor-account">
          <h1>My Profile</h1>

          {!advisor && <LoadingSpinner message="Loading..." />}
          {advisor && advisorBeingEdited ? (
            <div>
              <p className="py-4">
                View and edit your public <strong>profile page</strong> below and <strong>profile pic</strong>. This is what clients will
                see when they search for you and book a call.
              </p>
              <p>
                <Link to={`/${ApplicationRoutes.Advisor}/${advisor.slug}`}>
                  <img src={advisor.profilePicUrl} alt="Profile" className="circle-image headshot" style={{ maxWidth: '200px' }} />
                </Link>
              </p>
              <p>
                <Link to={`/${ApplicationRoutes.Advisor}/${advisor.slug}`} className="btn btn-primary">
                  View & edit public profile page for {advisor.firstName} {advisor.lastName}
                </Link>
              </p>
              <h2>My Rates</h2>

              <Row className="align-items-end">
                <Col sm="6" md="auto" className="d-flex">
                  <AdvisorEditRate rateType="hourly" advisor={advisor} onSave={(v) => setAdvisor(v)} />
                  <div className="me-4"></div>
                </Col>

                <Col sm="6" md="auto">
                  <AdvisorEditRate rateType="the-plan" advisor={advisor} onSave={(v) => setAdvisor(v)} />
                </Col>
              </Row>

              <h2>My Results Bullets</h2>
              <p>Edit the three bullets that will be shown below your photo on the search results page.</p>
              <Form onSubmit={onSaveShortBio}>
                <FormGroup>
                  <Input
                    type="text"
                    className="short-bio"
                    id="shortBio1"
                    value={shortBioLines![0]}
                    onChange={(event) => onShortBioChanged(0, event)}
                    maxLength={shortBioMaxLength}
                    disabled={bioBulletsAreSaving}
                  />
                  <Input
                    type="text"
                    className="short-bio"
                    id="shortBio2"
                    value={shortBioLines![1]}
                    onChange={(event) => onShortBioChanged(1, event)}
                    maxLength={shortBioMaxLength}
                    disabled={bioBulletsAreSaving}
                  />
                  <Input
                    type="text"
                    className="short-bio"
                    id="shortBio3"
                    value={shortBioLines![2]}
                    onChange={(event) => onShortBioChanged(2, event)}
                    maxLength={shortBioMaxLength}
                    disabled={bioBulletsAreSaving}
                  />
                </FormGroup>
                <Button color="primary" disabled={bioBulletsAreSaving}>
                  {bioBulletsAreSaving ? <LoadingDots /> : 'Save Results Bullets'}
                </Button>
              </Form>

              <h2>My States</h2>
              <ChooseStates userID={advisor.id} />
              <h2>Show Reviews</h2>
              <p>
                Choose whether the reviews you receive from clients will be shown publicly on the search results and on your profile page.
                We recommend leaving the box checked unless local regulations require you to hide reviews.
              </p>
              {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}

              <Form onSubmit={onSaveShowReviews}>
                <p>
                  <Label>
                    <Input
                      id="showReviews"
                      type="checkbox"
                      checked={advisor.showReviews}
                      onChange={(e) => {
                        setAdvisorBeingEdited((v) => ({
                          ...v!,
                          showReviews: e.target.checked
                        }));
                      }}
                    />
                    &nbsp;Show my reviews publicly
                  </Label>
                </p>
                <p>
                  <Button color="primary" disabled={saveShowReviewsLoading}>
                    {saveShowReviewsLoading ? <LoadingDots /> : 'Save Review Status'}
                  </Button>
                </p>
              </Form>

              <h2>The Plan - RightCapital Invitation Link</h2>
              <p>
                For The Plan clients, they will get an auto-populated welcome email with a link to access RightCapital. Insert your unique
                links below.
              </p>

              <Label>RightCapital Invitation Link</Label>

              <Input
                id="rightCapitalInvitationLink"
                className="short-bio"
                type="text"
                maxLength={rightCapitalFieldsMaxLength}
                value={advisorBeingEdited!.rightCapitalLink}
                onChange={(e) => {
                  onRightCapitalInputChanged(e, 'rightCapitalLink');
                }}
                disabled={rightCapitalLinksAreSaving}
              />

              <div className="mt-4"></div>

              <Label>RightCapital Login (for clients who already have a RightCapital account)</Label>

              <Input
                id="rightCapitalInvitationLink"
                className="short-bio"
                type="text"
                maxLength={rightCapitalFieldsMaxLength}
                value={advisorBeingEdited!.rightCapitalLogin}
                onChange={(e) => {
                  onRightCapitalInputChanged(e, 'rightCapitalLogin');
                }}
                disabled={rightCapitalLinksAreSaving}
              />

              <div className="mt-4"></div>

              <Button color="primary" disabled={rightCapitalLinksAreSaving} onClick={() => saveRightCapitalLinks()}>
                {rightCapitalLinksAreSaving ? <LoadingDots /> : 'Save Financial Planning Links'}
              </Button>

              <div className="my-5"></div>
              <div className="my-5">&nbsp;</div>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const shortBioMaxLength = 60;
const rightCapitalFieldsMaxLength = 500;
