import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import logoImage from '../../../../img/nectarine-logo.png';
import AdvisorsApiService from '../../../../services/api/AdvisorsApiService';
import UsersApiService from '../../../../services/api/UsersApiService';
import { ErrorLogService } from '../../../../services/ErrorLogService';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import ErrorsHelper from '../../../Helpers/ErrorsHelper';
import HttpHelper from '../../../Helpers/HttpHelper';
import LoadingDots from '../../../ui/LoadingAnimations/LoadingDots/LoadingDots';
import { useLoadingOverlayContext } from '../../../ui/LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';

const className = 'AccountRegister';

export const AccountRegister = () => {
  const navigate = useNavigate();

  const [newUserRegistrationInfo, setNewUserRegistrationInfo] = useState<Users_Register_EditModel>({
    email: '',
    password: '',
    confirmPassword: '',
    advisorSlug: '',
    firstName: '',
    lastName: '',
    isAffiliate: false
  });
  const [advisor, setAdvisor] = useState<Advisor_BasicDetails_ViewModel | null>(null);
  const [advisorLoading, setAdvisorLoading] = useState<boolean>(true);
  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });
  const [isAuthenticated, setIsAuthenticated] = useState<{ loading: boolean; authenticated: boolean }>({
    loading: true,
    authenticated: false
  });
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [affiliateSignUpMode, setAffiliateSignUpMode] = useState<boolean>(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const advisorSlug = queryParams.get('advisor');

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  useEffect(() => {
    const loadData = async () => {
      const isAffiliateMode = queryParams.get('affiliate') === 'true';

      setAffiliateSignUpMode(isAffiliateMode);

      if (advisorSlug) {
        const advisor = await AdvisorsApiService.getAdvisorDetailsByIdOrSlug(advisorSlug, false, false, false);
        setAdvisor(advisor);
      } else {
        setAdvisor(null);
      }

      setAdvisorLoading(false);

      setNewUserRegistrationInfo((v) => ({
        ...v,
        advisorSlug: advisorSlug,
        isAffiliate: isAffiliateMode
      }));

      const isAuthenticated = await AuthorizeService.isAuthenticated();
      await onAuthenticated(isAuthenticated, isAffiliateMode);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onAuthenticated(isAuthenticated: boolean, isAffiliateMode: boolean) {
    //If we're authenticated and it's affiliate mode, post to the server that we should be an affiliate
    if (isAuthenticated && isAffiliateMode) {
      const addedRole = await addAffiliateRole();

      if (addedRole) {
        redirectBackHereToRefreshRoles();
      }
    }

    setIsAuthenticated({ loading: false, authenticated: isAuthenticated });
  }

  function hideError() {
    setErrorSection({ text: null, show: false });
  }

  function showError(text: string) {
    setErrorSection({ show: true, text: text });
  }

  function onFormChange(e: React.ChangeEvent<HTMLInputElement>, propertyName: keyof Users_Register_EditModel) {
    hideError();

    setNewUserRegistrationInfo((a) => ({
      ...a,
      [propertyName]: e.target.value
    }));
  }

  async function addAffiliateRole() {
    try {
      let returnValue = false;

      await HttpHelper.put('/api/home/becomeaffiliate/', undefined, {
        200: () => {
          returnValue = true;
        },
        204: () => {
          showError('No changes were made.');
          returnValue = false;
        }
      });

      return returnValue;
    } catch (error) {
      ErrorLogService.logError(
        `${className} - addAffiliateRole()`,
        'An error occurred while attempting to add an affiliate role to the user.',
        error
      );

      showError(ErrorsHelper.getErrorMessageFromErrorObject(error));
      return false;
    }
  }

  async function onRegisterClick(e: React.FormEvent) {
    e.preventDefault();

    setIsSaving(true);

    try {
      showLoadingOverlay();

      await UsersApiService.register(newUserRegistrationInfo);
      setIsRegistered(true);

      redirectBackHereToRefreshRoles();

      setIsSaving(false);
    } catch (error: unknown) {
      const errorMessage = (error as Error).message || '';
      showError(errorMessage);
      setIsSaving(false);
    } finally {
      hideLoadingOverlay();
    }

    return false;
  }

  function redirectBackHereToRefreshRoles() {
    //Redirect back to login page, then back to this page so the client roles are refreshed
    const returnUrl = encodeURIComponent(window.location.href);
    navigate(`/${ApplicationRoutes.AuthenticationRoutes.Login}?returnUrl=${returnUrl}`);
  }

  return (
    <Container>
      <Helmet>
        <title>Register a New Account - {AppSettings.ApplicationName}</title>
      </Helmet>
      <Row>
        <Col lg="3" md="0" />

        {(isAuthenticated.loading || advisorLoading) && <LoadingSpinner />}

        {!isAuthenticated.loading && isAuthenticated.authenticated && advisor && (
          <Col lg="6" md="12" className="align-items-center justify-content-center pt-5">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>Welcome to Nectarine!</h2>
              <p>
                To finish scheduling your meeting with <strong>{advisor.fullName}</strong> click continue below to visit the advisor's page
                and then click the schedule meeting button.
              </p>
              <img
                src={advisor.profilePicUrl}
                className="circle-image headshot center"
                alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
              />
              <p>
                <Link to={`/${ApplicationRoutes.Advisor}/${advisor.slug}`} className="btn btn-primary btn-lg center">
                  Continue
                </Link>
              </p>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && !advisorLoading && isAuthenticated.authenticated && !advisor && !affiliateSignUpMode && (
          <Col lg="6" md="12" className="align-items-center justify-content-center pt-5">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>Hey! It looks like you're already logged in!</h2>
              <p>
                You can visit <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Root}`}>your account</Link>, our
                <Link to={ApplicationRoutes.Root}> home page</Link> or{' '}
                <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Logout}`} state={{ local: true }}>
                  logout
                </Link>
                .
              </p>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && !advisorLoading && isAuthenticated.authenticated && !advisor && affiliateSignUpMode && (
          <Col lg="6" md="12" className="align-items-center justify-content-center pt-5">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>You're all signed up!</h2>
              <p>
                Visit your <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Share_Full}`}>account</Link> to and click
                <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Share}`}> Share Nectarine</Link> to get started.
              </p>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && !advisorLoading && !isAuthenticated.authenticated && !isRegistered && (
          <Col lg="6" md="12" className="align-items-center justify-content-center">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              {advisor && (
                <p>
                  To book a meeting with {advisor.firstName}, <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}`}>login</Link> or
                  create your account below.
                </p>
              )}
              <h2>Create an Account</h2>
              <Form onSubmit={onRegisterClick}>
                <FormGroup>
                  <Label for="firstName">Name</Label>
                  <Row>
                    <Col sm="6">
                      <Input
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        type="text"
                        required
                        value={newUserRegistrationInfo.firstName}
                        onChange={(e) => onFormChange(e, 'firstName')}
                      />
                    </Col>
                    <Col sm="6">
                      <Input
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                        required
                        value={newUserRegistrationInfo.lastName}
                        onChange={(e) => onFormChange(e, 'lastName')}
                      />
                    </Col>
                  </Row>
                  {affiliateSignUpMode && (
                    <Row>
                      <Alert color="info" className="mt-2">
                        <strong>Important!</strong> Please use your real legal name for compliance and payment purposes.
                      </Alert>
                    </Row>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="name@example.com"
                    type="email"
                    required
                    value={newUserRegistrationInfo.email}
                    onChange={(e) => onFormChange(e, 'email')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Choose a password</Label>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    required
                    value={newUserRegistrationInfo.password}
                    onChange={(e) => onFormChange(e, 'password')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="confirmPassword">Re-enter password</Label>
                  <Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    required
                    value={newUserRegistrationInfo.confirmPassword}
                    onChange={(e) => onFormChange(e, 'confirmPassword')}
                  />
                </FormGroup>
                {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}
                <Button color="primary" size="lg" className="float-end mt-3" disabled={isSaving}>
                  {isSaving ? <LoadingDots /> : 'Create Account'}
                </Button>
                <div className="clearfix"></div>
              </Form>
            </div>
            <div className="text-center register-footer">
              <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}?returnUrl=${encodeURIComponent(window.location.href)}`}>
                Login
              </Link>{' '}
              &bull; <Link to={`/${ApplicationRoutes.Contact}`}>Contact</Link> &bull; <Link to={`/${ApplicationRoutes.Terms}`}>Terms</Link>{' '}
              &bull; <Link to={`/${ApplicationRoutes.Privacy}`}>Privacy</Link>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && isRegistered && (
          <Col lg="6" md="12" className="align-items-center justify-content-center">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>Welcome!</h2>
              <p>
                Your account is created and ready to go! Now you can visit{' '}
                <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Root}`}>your account</Link>.
              </p>
            </div>
            <div className="text-center register-footer">
              <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}?returnUrl=${encodeURIComponent(window.location.href)}`}>
                Login
              </Link>{' '}
              &bull; <Link to={`/${ApplicationRoutes.Contact}`}>Contact</Link> &bull; <Link to={`/${ApplicationRoutes.Terms}`}>Terms</Link>{' '}
              &bull; <Link to={`/${ApplicationRoutes.Privacy}`}>Privacy</Link>
            </div>
          </Col>
        )}

        <Col lg="3" md="0" />
      </Row>
    </Container>
  );
};
