import './Toggle.scss';

interface ToggleProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
  isDisabled?: boolean;
}

const Toggle: React.FC<ToggleProps> = (props: ToggleProps) => {
  return (
    <div
      // Give it a tabindex so it can be focused by the user (and able to be toggled with the keyboard)
      tabIndex={0}
      className={`toggleContainer ${props.isDisabled ? 'isDisabled' : ''} ${props.value ? 'isOn' : ''}`}
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();

          props.onChange(!props.value);
        }
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.isDisabled) {
          return;
        }

        props.onChange(!props.value);
      }}
    >
      <div className="toggleButton"></div>
    </div>
  );
};

export default Toggle;
