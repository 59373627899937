import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';
import { AppSettings } from '../../../constants';
import useNavigationService from '../../../hooks/UseNavigationService';
import UsersApiService from '../../../services/api/UsersApiService';
import { ReviewForm } from '../../ReviewForm/ReviewForm';
import LoadingSpinner from '../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';

export const LeaveAReview: React.FC = () => {
  const [consumer, setConsumer] = useState<User_BasicDetails_ViewModel | null>(null);
  const [advisorBeingReviewed, setAdvisorBeingReviewed] = useState<User_BasicDetails_ViewModel | null>(null);
  const [review, setReview] = useState<Reviews_Review_EditModel>({
    revieweeId: '',
    reviewerId: '',
    body: '',
    displayName: '',
    privateComments: '',
    rating: 0,
    title: '',
    competenceRating: 0,
    coverageRating: 0,
    recapRating: 0,
    recommendationRating: 0
  });
  const [showSavedMessage, setShowSavedMessage] = useState<boolean>(false);

  const { navigateTo_Error } = useNavigationService();

  useEffect(() => {
    const loadData = async () => {
      // NOTE: The URL is in the format leaveareview/{AdvisorId}/{ConsumerId}
      const urlParts = window.location.href.split('/');
      const userIds = urlParts.slice(urlParts.length - 2);
      const advisorId = userIds[0];
      const consumerId = userIds[1];

      if (advisorId && consumerId) {
        const [advisorUserInfo, consumerUserInfo] = await Promise.all([
          UsersApiService.getBasicUserInformation(advisorId),
          UsersApiService.getBasicUserInformation(consumerId)
        ]);

        setAdvisorBeingReviewed(advisorUserInfo);
        setConsumer(consumerUserInfo);

        setReview((currReview) => {
          return { ...currReview, revieweeId: advisorUserInfo.id, reviewerId: consumerUserInfo.id };
        });
      } else {
        navigateTo_Error();
      }
    };

    loadData();
  }, [navigateTo_Error]);

  const showSuccess = () => {
    setShowSavedMessage(true);
  };

  return (
    <>
      <Helmet>
        <title>Leave a Review - {AppSettings.ApplicationName}</title>
      </Helmet>
      <Row>
        <Col lg="12" xl="2"></Col>
        <Col>
          {!(advisorBeingReviewed || consumer) ? <LoadingSpinner message="Loading..." /> : <></>}

          {advisorBeingReviewed && consumer && !showSavedMessage ? (
            <ReviewForm
              consumer={consumer}
              advisor={advisorBeingReviewed}
              review={review}
              onSuccessfulSave={showSuccess}
              isNonAuthenticatedReview
            />
          ) : (
            <></>
          )}

          {advisorBeingReviewed && showSavedMessage ? (
            <div>
              <h1>Thank you!</h1>
              <p>
                Thank you so much for taking a moment to review {advisorBeingReviewed.fullName}. Your review will help future investors
                choose their best advisor!
              </p>
              {!consumer?.hasRegistered ? (
                <p>
                  It looks like you haven't created your account yet! If you'd like to create your account visit our
                  <a href="/register"> registration page</a> to set your password. Your account allows you to book meetings with your
                  advisor, see your reviews and more.
                </p>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </Col>
        <Col lg="12" xl="2"></Col>
      </Row>
    </>
  );
};
