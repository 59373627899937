//@ts-check
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, Input, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import DateHelper from '../../../Helpers/DateHelper';
import HttpHelper from '../../../Helpers/HttpHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

export const AdminPayments = (props) => {
  const [data, setData] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState(null);
  const [newPayment, setNewPayment] = useState(null);

  const userTypeLower = props.userTitle.toLowerCase();

  const payPeriodChanged = async (event) => {
    event.preventDefault();
    var payPeriod = event.target.value;
    setSelectedPayPeriod(payPeriod);
  };

  useEffect(() => {
    if (selectedPayPeriod) {
      searchPayments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayPeriod]);

  const searchPayments = async () => {
    setData(null);

    // console.log('selectedPayPeriod', selectedPayPeriod);

    const data = await HttpHelper.get('api/admin/' + userTypeLower + 'payments/' + selectedPayPeriod);
    // console.log('Data:', data);
    setData(data);
    setFilteredEvents(data.events);
  };

  const filter = (e, userID) => {
    e.preventDefault();
    var filteredEvents;
    filteredEvents = data.events.filter((event) => event[userTypeLower + 'Id'] == userID);
    setFilteredEvents(filteredEvents);
    return false;
  };

  const logPaymentMode = (e, a) => {
    filter(e, a.userId);
    setUser(a);
    setSelectedEvents([]);
    const today = new Date().toISOString().split('T')[0];
    setNewPayment({ paidAt: today });
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedEvents((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedEvents((prevSelected) => prevSelected.filter((state) => state !== value));
    }
  };

  function onFormChange(e) {
    // console.log('e', e);
    setNewPayment((a) => ({
      ...a,
      [e.target.id]: e.target.value
    }));
    // console.log('onFormChange', e);
  }

  const logPayment = async (e) => {
    e.preventDefault();

    if (!selectedEvents || selectedEvents.length == 0) {
      alert('Please select at least one event to log a payment.');
      return false;
    }

    // Package the selected events with the newPayment object
    newPayment.eventIDs = selectedEvents;

    //Treat the date as UTC
    newPayment.paidAt = new Date(newPayment.paidAt + 'T00:00:00Z');

    const url = '/api/admin/' + userTypeLower + 'payments/' + user.userId + '/logpayment';
    HttpHelper.post(url, newPayment)
      .then(() => {
        // console.log('End logPayment');
        setUser(null);
        searchPayments();
      })
      .catch((error) => {
        /*showError(error);*/
        alert(error);
        console.error('Unable to add item.', error);
      });

    return false;
  };

  // Format date to 'YYYY-MM-DD' string
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <Container>
      <Helmet>
        <title>
          {AppSettings.ApplicationName} - {props.userTitle} Payments
        </title>
      </Helmet>
      <Row>
        <Col>
          {!user && (
            <Form>
              <select id="payPeriod" name="payPeriod" onChange={payPeriodChanged} value={selectedPayPeriod}>
                <option value="">Choose a Pay Period</option>
                <option value="unsubmitted-notes">Unsubmitted Notes</option>
                {props.payPeriods.map((range, index) => (
                  <option key={index} value={formatDate(range.start)}>
                    {DateHelper.shortDateFormat(range.start)} - {DateHelper.shortDateFormat(range.end)}
                  </option>
                ))}
              </select>{' '}
            </Form>
          )}
          {user && (
            <div>
              <h2>Log a Payment For {user.userFullName}</h2>
              <p>
                Select the events below to log a payment for {user.userFullName}. The total number of events and amount owed will appear
                below. Once that is correct, click "Log Payment" to mark all of those events as paid and create a record of the payment. For
                now, the actual payment will still need to be sent outside of Nectarine.
              </p>
              <p>
                <strong>Events Selected:</strong> {selectedEvents.length}
              </p>
              <p>
                <strong>Payment Amount:</strong> $
                {filteredEvents
                  .filter((event) => selectedEvents.includes(event.id))
                  .reduce((sum, event) => sum + event[userTypeLower + 'OwedAmount'], 0)
                  .toLocaleString()}
              </p>
              <p>
                <strong>Payment Date:</strong>
                <Input type="date" id="paidAt" name="paidAt" onChange={onFormChange} style={{ width: '150px' }} value={newPayment.paidAt} />
              </p>
              <Button
                color="light"
                size="lg"
                onClick={() => {
                  setUser(null);
                  setFilteredEvents(data.events);
                }}
              >
                Cancel
              </Button>{' '}
              <Button color="primary" size="lg" onClick={(e) => logPayment(e)}>
                Log Payment
              </Button>
              <hr />
            </div>
          )}

          {selectedPayPeriod && !data && <LoadingSpinner message="Loading..." />}

          {data && data.length == 0 && <div>No data.</div>}

          {data && data.userPaymentDetails && !user && (
            <div>
              <h2>Payment by {props.userTitle} Summary</h2>
              <p>
                Showing <strong>{data.userPaymentDetails.length}</strong> {props.userTitle.toLowerCase()}s. Total Owed:{' '}
                <strong>${data.userPaymentDetails.reduce((sum, user) => sum + user.totalOwedAmount, 0).toLocaleString()}</strong>
              </p>
              <table className="table">
                <thead>
                  <tr>
                    <th>{props.userTitle}</th>
                    <th>Total Paid</th>
                    <th>Events Paid</th>
                    <th>Total Owed</th>
                    <th>Events Owed</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.userPaymentDetails.map((a) => (
                    <tr key={a.userId}>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${a.userId}/edit`}>{a.userFullName}</Link>

                        {!a.userFullName && <span>{a.userEmail}</span>}

                        {a.userId && <AdminImpersonateLink userId={a.userId} />}
                      </td>

                      <td>${a.totalPaidAmount.toLocaleString()}</td>
                      <td>{a.eventsPaid.toLocaleString()}</td>
                      <td>${a.totalOwedAmount.toLocaleString()}</td>
                      <td>{a.eventsOwed.toLocaleString()}</td>
                      <td>
                        {a.totalOwedAmount > 0 && (
                          <Button color="primary" size="sm" onClick={(e) => logPaymentMode(e, a)}>
                            Log Payment...
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {filteredEvents && (
            <div>
              <h2>Events Details</h2>
              <p>
                Showing <strong>{filteredEvents.length}</strong> events. Total Owed:{' '}
                <strong>
                  $
                  {filteredEvents
                    .reduce(
                      (sum, event) => sum + (event.paymentSuccessful && event.status == 'Active' ? event[userTypeLower + 'OwedAmount'] : 0),
                      0
                    )
                    .toLocaleString()}
                </strong>
              </p>

              {/* Select all checkboxes link */}
              {user && (
                <p>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedEvents(filteredEvents.filter((event) => event[userTypeLower + 'Paid'] == false).map((event) => event.id));
                    }}
                  >
                    Select All
                  </a>
                  {' - '}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedEvents([]);
                    }}
                  >
                    Select None
                  </a>
                </p>
              )}

              <table className="table">
                <thead>
                  <tr>
                    {user && <th></th>}
                    <th>{props.userTitle}</th>
                    <th>Consumer</th>
                    <th>
                      <i className="fa-duotone fa-peach light-blue"></i>
                    </th>
                    <th>State</th>
                    <th>Start Time</th>
                    <th>Notes Sent</th>
                    <th>Status</th>
                    <th>Client Paid</th>
                    <th>{props.userTitle} Paid</th>
                    <th>{props.userTitle} Owed</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEvents.map((event) => (
                    <tr key={event.id}>
                      {user && (
                        <td>
                          {!event.userPaid && (
                            <Input
                              type="checkbox"
                              value={event.id}
                              checked={selectedEvents.includes(event.id)}
                              onChange={handleCheckboxChange}
                            />
                          )}
                        </td>
                      )}
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.userId}/edit`}>
                          {event[userTypeLower + 'FullName']}
                        </Link>

                        {!event.userFullName && <span>{event.userEmail}</span>}

                        {event.userId && <AdminImpersonateLink userId={event.userId} />}
                      </td>

                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.consumerId}/edit`}>{event.consumerFullName}</Link>
                        {!event.consumerFullName && <span>{event.consumerEmail}</span>}
                        {event.consumerId && <AdminImpersonateLink userId={event.consumerId} />}{' '}
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.consumerId}/report`}>
                          <i className="fa-duotone fa-file-invoice"></i>
                        </Link>
                      </td>
                      <td>{event.isNectarine && <i className="fa-duotone fa-peach light-blue"></i>}</td>
                      <td>{event.consumerState}</td>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Events_Full}/${event.id}/edit`}>
                          {DateHelper.mediumDateFormat(event.startTime)}
                        </Link>
                      </td>
                      <td>{DateHelper.mediumDateFormat(event.notesSentAt)}</td>
                      <td>{event.status}</td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: event.paymentCurrency ? event.paymentCurrency : 'USD',
                          maximumFractionDigits: 0
                        }).format(event.paymentAmount)}
                        {!event.paymentSuccessful && event.paymentAmount > 0 && (
                          <span className="text-danger">
                            {' '}
                            <strong>FAILED!</strong>
                          </span>
                        )}
                      </td>
                      <td>${event[userTypeLower + 'PaidAmount'].toLocaleString()}</td>
                      <td>${event[userTypeLower + 'OwedAmount'].toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
