import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { ApplicationRoutes, FeatureFlags, UserRoles } from '../../../../constants';
import { useGeneralContext } from '../../../../services/GeneralContext';
import LoginMenu from '../../../api-authorization/LoginMenu';
import logoImage from './../../../../img/nectarine-logo.png';
import snowman from './../../../../img/snowman.png';
import './NavMenu.scss';

export const NavMenu: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);

  const { checkUserRole } = useGeneralContext();
  const userIsAdmin = checkUserRole(UserRoles.Admin);

  const { checkFeatureFlag } = useGeneralContext();
  const showWinterEasterEggs = checkFeatureFlag(FeatureFlags.WinterEasterEggs) || userIsAdmin;

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <header>
      <Navbar className="home-top-navbar navbar-expand-md navbar-toggleable-md ng-white mb-3" container light>
        <NavbarBrand tag={Link} to={ApplicationRoutes.Root}>
          <img src={logoImage} alt="Nectarine" className="logo" />

          {showWinterEasterEggs ? <img src={snowman} style={{ width: '30px' }} className="ms-3 position-absolute" /> : <></>}
        </NavbarBrand>

        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <a href="/#howitworks" className="nav-link">
              How it Works
            </a>

            <a href="/#pricing" className="nav-link">
              Pricing
            </a>
            <NavItem>
              <a href="/#experts" className="nav-link">
                Experts
              </a>
            </NavItem>

            <LoginMenu location="top" />

            <NavItem>
              <Button className="header-button nav-link" color="primary" href="/#home">
                Get Started
              </Button>
            </NavItem>
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
};
