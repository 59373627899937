import { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react';
import RolesHelper from '../components/Helpers/RolesHelper';
import { FeatureFlags, UserRoles } from '../constants';
import SystemApiService from './api/SystemApiService';

interface GeneralContextType {
  cachedDataRef: React.MutableRefObject<{ [index: string]: unknown }>;
  initializeFeatureFlags: () => Promise<void>;
  checkFeatureFlag: (featureFlagId: string) => boolean;
  checkUserRole: (userRole: string) => boolean;
}

// A general context for use as a general data store / service class
const GeneralContext = createContext<GeneralContextType | undefined>(undefined);

const GeneralContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // A ref object to hold generic data of any type
  const cachedDataRef = useRef<{ [index: string]: unknown }>({});

  // Feature flags and their availability
  const [featureFlagsAndValues, setFeatureFlagsAndValues] = useState<{ [index: string]: boolean }>({});

  // The current user's roles
  const [userRoles, setUserRoles] = useState<string[]>([]);

  const initializeFeatureFlags = useCallback(async () => {
    try {
      const roles = await RolesHelper.getRoles();

      setUserRoles(roles);

      const userIsAdmin = roles.includes(UserRoles.Admin);

      const featureFlagsToGet = [FeatureFlags.ThePlanWelcomeEmail, FeatureFlags.WinterEasterEggs];

      const results = await Promise.all(
        featureFlagsToGet.map(async (v) => {
          const result = await SystemApiService.getFeatureFlag(v);

          return {
            id: v,
            result
          };
        })
      );

      const newObj: { [index: string]: boolean } = {};

      results.forEach((currResult) => {
        // NOTE: Admin users can see every feature flag, obvs
        newObj[currResult.id] = userIsAdmin ? true : currResult.result;
      });

      setFeatureFlagsAndValues(newObj);
    } catch (error) {}
  }, []);

  const checkFeatureFlag = useCallback(
    (featureFlagId: string): boolean => {
      return featureFlagsAndValues[featureFlagId] ?? false;
    },
    [featureFlagsAndValues]
  );

  const checkUserRole = useCallback(
    (userRole: string): boolean => {
      return userRoles.includes(userRole);
    },
    [userRoles]
  );

  return (
    <GeneralContext.Provider
      value={{
        cachedDataRef,
        checkFeatureFlag,
        checkUserRole,
        initializeFeatureFlags
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

const useGeneralContext = () => {
  const context = useContext(GeneralContext);

  if (!context) {
    throw new Error('useGeneralContext() must be used within an GeneralContext. Please verify your DOM structure.');
  }
  return context;
};

export { GeneralContextProvider, useGeneralContext };
