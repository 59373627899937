import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

export default class SystemApiService extends ApiServiceBase {
  private static className = 'SystemApiService';

  public static async getFeatureFlag(featureFlagId: string): Promise<boolean> {
    try {
      const result = await this.makeApiCall(() => HttpHelper.get<boolean>(`api/system/feature/${featureFlagId}`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getFeatureFlag()`,
        `An error occurred while getting feature flag value: ${featureFlagId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
