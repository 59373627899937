import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import wateringImage from './../../../../img/watering-plants.png';

const ErrorPage: React.FC = () => {
  useEffect(() => {
    // TODO - in the future, send any applicable error info to the API endpoint with the ErrorLogService
  }, []);

  return (
    <>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Error</title>
      </Helmet>
      <Container>
        <Row>
          <Col xs="12" md="6">
            <h1>
              Uh oh! <br />
              Something went wrong.
            </h1>

            <div className="my-5">
              We're sorry but something went wrong. We've let the {AppSettings.ApplicationName} team know!
              <br />
              Let's get you back on track by heading to the <Link to={ApplicationRoutes.Root}>home page</Link>.
            </div>

            <div className="center my-5">
              <Link className="btn btn-primary" to={ApplicationRoutes.Root}>
                Go Back Home
              </Link>
            </div>
          </Col>

          <Col xs="12" md="6">
            <img src={wateringImage} className="" alt="Watering plants" style={{ width: '80%' }} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ErrorPage;
