import { useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { Subscription } from 'rxjs';
import './ConfirmationOverlay.scss';
import { ConfirmationOverlayPrompt, useConfirmationOverlayContext } from './ConfirmationOverlayContext';

const ConfirmationOverlay: React.FC = () => {
  const allSubscriptions = useRef<Subscription>(new Subscription());

  const { confirmationOverlayPrompt$, hideConfirmationOverlay } = useConfirmationOverlayContext();

  const [displayedPrompt, setDisplayedPrompt] = useState<ConfirmationOverlayPrompt | null>(null);
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
  const [isHiding, setIsHiding] = useState<boolean>(false);

  useEffect(() => {
    allSubscriptions.current.add(
      confirmationOverlayPrompt$.subscribe((v) => {
        setIsDisplayed(v !== null);
        setIsHiding(false);

        setDisplayedPrompt(v);
      })
    );

    const allSubscriptionsObj = allSubscriptions.current;

    return () => {
      allSubscriptionsObj.unsubscribe();
    };
  }, [confirmationOverlayPrompt$]);

  return (
    <div className={`confirmationOverlay ${isDisplayed ? '' : 'd-none'} ${isHiding ? 'isHiding' : ''}`}>
      {displayedPrompt ? (
        <>
          <div className="promptText text-center">
            <div className="innerText">{displayedPrompt.text}</div>
          </div>

          {displayedPrompt.text2 ? (
            <div className="promptText text-center">
              <div className="innerText">{displayedPrompt.text2}</div>
            </div>
          ) : (
            <></>
          )}

          <div className="buttonsArea mt-4 d-flex gap-5 justify-content-center">
            <Button
              color="light"
              onClick={() => {
                setIsHiding(true);
                hideConfirmationOverlay();

                displayedPrompt.noAction?.();
              }}
            >
              {displayedPrompt.noButtonText ?? 'No'}
            </Button>

            <Button
              color="primary"
              onClick={() => {
                setIsHiding(true);
                hideConfirmationOverlay();

                displayedPrompt.yesAction();
              }}
            >
              {displayedPrompt.yesButtonText ?? 'Yes'}
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ConfirmationOverlay;
