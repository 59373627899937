import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, UserRoles } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import DateHelper from '../../../Helpers/DateHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

export const AdminUsers: React.FC = () => {
  const [users, setUsers] = useState<User_BasicDetails_ViewModel[] | null>(null);
  const [filterRole, setFilterRole] = useState<string | null>(null);

  React.useEffect(() => {
    filterUsersByRole(UserRoles.Advisor);
  }, []);

  const filterUsersByRole = async (roleName: string) => {
    setUsers(null);
    setFilterRole(roleName);

    const usersInRole = await AdminApiService.getUsersInRole(roleName);
    setUsers(usersInRole);
  };

  const populateAllUsers = async () => {
    const users = await AdminApiService.getAllUsers();
    setUsers(users);
    setFilterRole('All');
  };

  return (
    <Container>
      <Helmet>
        <title>Admin / All Users</title>
      </Helmet>
      <Row>
        <Col>
          <h1>Users</h1>
          <p>
            Show only:{' '}
            <Button color={filterRole === UserRoles.Advisor ? 'info' : 'light'} onClick={(e) => filterUsersByRole(UserRoles.Advisor)}>
              Advisors
            </Button>{' '}
            <Button color={filterRole === UserRoles.Affiliate ? 'info' : 'light'} onClick={(e) => filterUsersByRole(UserRoles.Affiliate)}>
              Affiliates
            </Button>{' '}
            <Button color={filterRole === UserRoles.Consumer ? 'info' : 'light'} onClick={(e) => filterUsersByRole(UserRoles.Consumer)}>
              Consumers
            </Button>{' '}
            <Button color={filterRole === UserRoles.Admin ? 'info' : 'light'} onClick={(e) => filterUsersByRole(UserRoles.Admin)}>
              Admins
            </Button>{' '}
            <Button color={filterRole == 'All' ? 'info' : 'light'} onClick={populateAllUsers}>
              All
            </Button>{' '}
          </p>

          {users ? (
            <p>
              Showing <strong>{users.length}</strong> results
            </p>
          ) : (
            <></>
          )}

          <table className="table admin-users">
            <thead>
              <tr>
                <th>Last</th>
                <th>First</th>
                <th>Email</th>
                <th>State</th>
                {filterRole == 'Advisor' ? (
                  <th>
                    <i className="fa-duotone fa-peach light-blue"></i>
                  </th>
                ) : (
                  <th></th>
                )}
                {filterRole == 'Advisor' ? <th>Next Available</th> : <th></th>}
                {filterRole == 'Consumer' || filterRole == 'Affiliate' ? <th>Report</th> : <th></th>}
                <th>Impersonate</th>
              </tr>
            </thead>
            {!users && (
              <tbody>
                <tr>
                  <td colSpan={99}>
                    <LoadingSpinner message="Loading..." />
                  </td>
                </tr>
              </tbody>
            )}
            {users && (
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${user.id}/edit`}>{user.lastName}</Link>
                    </td>
                    <td>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${user.id}/edit`}>{user.firstName}</Link>
                    </td>
                    <td>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${user.id}/edit`}>{user.email}</Link>
                    </td>
                    <td>{user.state}</td>

                    {filterRole === 'Advisor' ? (
                      <td>{user.isNectarine ? <i className="fa-duotone fa-peach light-blue"></i> : ''}</td>
                    ) : (
                      <td></td>
                    )}

                    {filterRole === 'Advisor' && user.nextAvailability ? (
                      <td>
                        {new Date(user.nextAvailability) > new Date() && <span>{DateHelper.casualDateFormat(user.nextAvailability)}</span>}
                      </td>
                    ) : (
                      <td></td>
                    )}

                    {filterRole === 'Consumer' || filterRole === 'Affiliate' ? (
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${user.id}/report`}>Compliance Report</Link>
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td>
                      <AdminImpersonateLink userId={user.id} />
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </Col>
      </Row>
    </Container>
  );
};
