import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { ApplicationRoutes, UserRoles } from '../../constants';
import RolesHelper from '../Helpers/RolesHelper';
import Snow from '../ui/ee/Snow/Snow';
import AuthorizeService from './AuthorizeService';

interface LoginMenuProps {
  location?: string;
}

const LoginMenu: React.FC<LoginMenuProps> = ({ location }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [roles, setRoles] = useState<string[] | null>(null);
  const [userName, setUserName] = useState<string | null>(null);

  useEffect(() => {
    const subscription = AuthorizeService.subscribe(() => loadData());
    loadData();

    return () => {
      AuthorizeService.unsubscribe(subscription);
    };
  }, []);

  const loadData = async () => {
    const [authStatus, user] = await Promise.all([AuthorizeService.isAuthenticated(), AuthorizeService.getUser()]);
    const roles = await RolesHelper.getRoles();
    setIsAuthenticated(authStatus);
    setUserName(user?.name || null);
    setRoles(roles);
  };

  const authenticatedView = (userName: string, profilePath: string, logoutPath: string, logoutState: object) => {
    if (location === 'top') {
      return (
        <>
          {roles?.includes(UserRoles.Consumer) && (
            <NavItem>
              <Snow>
                <NavLink to={`/${ApplicationRoutes.ClientAccountRoutes.Root}`} tag={Link} className="btn btn-light login-button">
                  My Account
                </NavLink>
              </Snow>
            </NavItem>
          )}
          {(roles?.includes(UserRoles.Advisor) || roles?.includes(UserRoles.Admin)) && (
            <NavItem>
              <Snow>
                <NavLink to={`/${ApplicationRoutes.AdvisorAccountRoutes.Root}`} tag={Link} className="btn btn-light login-button">
                  Advisor Home
                </NavLink>
              </Snow>
            </NavItem>
          )}
          {roles?.includes(UserRoles.Admin) && (
            <NavItem>
              <Snow>
                <NavLink to={`/${ApplicationRoutes.AdminRoutes.Root}`} tag={Link} className="btn btn-light login-button">
                  Admin
                </NavLink>
              </Snow>
            </NavItem>
          )}
        </>
      );
    }

    return (
      <>
        <NavItem>
          <NavLink tag={Link} to={profilePath}>
            Hello {userName}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink replace tag={Link} to={logoutPath} state={logoutState}>
            Logout
          </NavLink>
        </NavItem>
      </>
    );
  };

  const anonymousView = (registerPath: string, loginPath: string) => {
    if (location === 'top') {
      return (
        <>
          <NavItem>
            <NavLink tag={Link} className="btn btn-light login-button" to={loginPath}>
              Login
            </NavLink>
          </NavItem>
        </>
      );
    }
    return (
      <>
        <NavItem>
          <NavLink tag={Link} to={registerPath}>
            Register
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={loginPath}>
            Login
          </NavLink>
        </NavItem>
      </>
    );
  };

  const registerPath = `/${ApplicationRoutes.Register}`;
  const loginPath = `/${ApplicationRoutes.AuthenticationRoutes.Login}`;
  const profilePath = `/${ApplicationRoutes.AuthenticationRoutes.Profile}`;
  const logoutPath = `/${ApplicationRoutes.AuthenticationRoutes.Logout}`;
  const logoutState = { local: true };

  return isAuthenticated ? authenticatedView(userName || '', profilePath, logoutPath, logoutState) : anonymousView(registerPath, loginPath);
};

export default LoginMenu;
