import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface ModalWizardPage {
  id: string;
  title: string | JSX.Element;
  content: JSX.Element;
  nextPageButtonText?: string;
  previousPageButtonText?: string;
  showCancelButton?: boolean;
  customButtons?: ModalWizardPageButton[];
  canContinue?: () => boolean;
  onNextButtonClick?: () => void;
}

interface ModalWizardPageButton {
  text: string;
  onClick: () => void;
}

interface ModalWizardProps {
  pages: ModalWizardPage[];
  onModalClose: () => void;
}

const ModalWizard: React.FC<ModalWizardProps> = (props: ModalWizardProps) => {
  const [currPageIndex, setCurrPageIndex] = useState<number>(0);
  const [modalIsDisplayed, setModalIsDisplayed] = useState<boolean>(true);

  const toggle = (): void => {
    setModalIsDisplayed((v) => !v);
  };

  const closeModal = (): void => {
    setModalIsDisplayed(false);

    // Wait a second so the modal has time to finish animating out
    setTimeout(() => {
      props.onModalClose();
    }, 500);
  };

  const changePage = (direction: 'prev' | 'next'): void => {
    if (direction === 'prev') {
      setCurrPageIndex((v) => Math.max(v - 1, 0));
    } else {
      setCurrPageIndex((v) => Math.min(v + 1, props.pages.length - 1));
    }
  };

  const getDisplayedPage = (): ModalWizardPage => {
    return props.pages[currPageIndex];
  };

  return (
    <Modal isOpen={modalIsDisplayed} toggle={toggle}>
      <ModalHeader toggle={closeModal}>{getDisplayedPage().title}</ModalHeader>

      <ModalBody>{getDisplayedPage().content}</ModalBody>

      <ModalFooter>
        <div className="d-flex gap-3">
          {(getDisplayedPage().showCancelButton ?? false) ? (
            <Button color="light" className="px-3" onClick={() => closeModal()}>
              Cancel
            </Button>
          ) : (
            <></>
          )}

          {currPageIndex > 0 ? (
            <Button color="light" className="px-4" onClick={() => changePage('prev')}>
              {getDisplayedPage().previousPageButtonText ?? 'Back'}
            </Button>
          ) : (
            <></>
          )}

          {currPageIndex === props.pages.length - 1 && getDisplayedPage().customButtons?.length ? (
            <></>
          ) : (
            <Button
              color="primary"
              disabled={!(getDisplayedPage().canContinue?.() ?? true)}
              onClick={() => {
                const displayedPage = getDisplayedPage();
                displayedPage.onNextButtonClick?.();

                if (currPageIndex < props.pages.length - 1 && (getDisplayedPage().canContinue?.() ?? true)) {
                  changePage('next');
                }
              }}
            >
              {getDisplayedPage().nextPageButtonText ?? 'Next'}
            </Button>
          )}

          {(getDisplayedPage().customButtons ?? []).map((currFinishButton) => {
            return (
              <Button color="primary" onClick={() => currFinishButton.onClick()} key={currFinishButton.text}>
                {currFinishButton.text}
              </Button>
            );
          })}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ModalWizard;
