import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings, CommonApiBadRequestErrors } from '../../../../constants';
import useNavigationService from '../../../../hooks/UseNavigationService';
import AdvisorsApiService from '../../../../services/api/AdvisorsApiService';
import UsersApiService from '../../../../services/api/UsersApiService';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import ErrorsHelper from '../../../Helpers/ErrorsHelper';
import GeneralHelper from '../../../Helpers/GeneralHelper';
import StateHelper from '../../../Helpers/StateHelper';
import { useLoadingOverlayContext } from '../../../ui/LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import wateringImage from './../../../../img/watering-plants.png';

export const ThePlanTerms = () => {
  const [advisor, setAdvisor] = useState<Advisor_BasicDetails_ViewModel | null>(null);
  const [advisors, setAdvisors] = useState<Advisor_BasicDetails_ViewModel[] | null>(null);
  const [state, setState] = useState<string | null>(localStorage.getItem('userState') ?? null);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });
  const [agreeIsChecked, setAgreeIsChecked] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [thePlanComplianceDocument, setThePlanComplianceDocument] = useState<Advisor_ComplianceDocument_ViewModel | null>(null);

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  const { slugOrThePlanDetailId } = useParams();

  const { navigateTo_ThePlanTerms, navigateTo_Error } = useNavigationService();

  useEffect(() => {
    const getAuthStatus = async () => {
      const isAuthenticatedResult = await AuthorizeService.isAuthenticated();
      setIsAuthenticated(isAuthenticatedResult);
    };

    getAuthStatus();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const isGuid = GeneralHelper.isStringAGuid(slugOrThePlanDetailId ?? '');
      const slug = !isGuid ? slugOrThePlanDetailId : '';
      const thePlanDetailId = isGuid ? slugOrThePlanDetailId : '';

      try {
        if (slug) {
          const advisorObj = await AdvisorsApiService.getAdvisorDetailsByIdOrSlug(slug, false, false, false);

          setAdvisor(advisorObj);
        } else if (thePlanDetailId) {
          const result = await UsersApiService.getAdvisorComplianceDocument_ThePlan(thePlanDetailId);

          setThePlanComplianceDocument(result);

          const advisorObj = await AdvisorsApiService.getAdvisorDetailsByIdOrSlug(result.advisorId, false, false, false);
          setAdvisor(advisorObj);
        } else {
          let advisorsList = await AdvisorsApiService.getAllAdvisorDetails();
          advisorsList = sortBy(advisorsList, (v) => v.firstName);
          setAdvisors(advisorsList);
        }
      } catch {
        navigateTo_Error();
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onAgreeChecked(e: React.ChangeEvent<HTMLInputElement>) {
    setAgreeIsChecked(e.target.checked);
  }

  const onStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState(e.target.value);

    localStorage.setItem('userState', e.target.value);
  };

  function docSeparator(i: number, length: number) {
    if (i == 0 && length == 2) return <span> and </span>;
    if (i < length - 2) return <span>, </span>;
    if (i < length - 1) return <span>, and </span>;
    return <span> </span>;
  }

  async function onAdvisorClick(e: React.MouseEvent, advisorSlug: string) {
    e.preventDefault();

    const data = await AdvisorsApiService.getAdvisorDetailsByIdOrSlug(advisorSlug, false, false, false);

    setAdvisor(data);

    navigateTo_ThePlanTerms(advisorSlug);

    return false;
  }

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function isAgreeButtonEnabled() {
    return agreeIsChecked && isAuthenticated;
  }

  function hideError() {
    setErrorSection({ show: false, text: null });
  }

  async function onAgreeToTermsSubmit(e: React.FormEvent) {
    e.preventDefault();
    hideError();

    try {
      showLoadingOverlay();

      await UsersApiService.agreeToPlanTerms(advisor!.id, {
        product: 'ThePlan',
        stateAbbreviation: state!,
        additionalComplianceDocumentIds: [thePlanComplianceDocument?.id ?? ''].filter((v) => v)
      });

      setSuccess(true);
    } catch (error) {
      const errorObj = error as { message: string };

      if (errorObj.message === CommonApiBadRequestErrors.NotLoggedIn) {
        setIsAuthenticated(false);
        hideError();
      } else {
        console.log(errorObj.message);
        showError(ErrorsHelper.getErrorMessageFromErrorObject(errorObj.message));
      }
    } finally {
      hideLoadingOverlay();
    }

    return false;
  }

  function getComplianceDocumentsToDisplay(): Advisor_ComplianceDocument_ViewModel[] {
    const returnValue = [...(advisor?.nectarinePlanComplianceDocuments ?? [])];

    // If we have a custom compliance document for "the plan", use it, otherwise
    // use whatever we pulled for the advisor
    if (thePlanComplianceDocument) {
      returnValue.push(thePlanComplianceDocument);
    } else {
      returnValue.push(...(advisor?.advisorPlanComplianceDocuments ?? []));
    }

    return returnValue;
  }

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - The Plan - Agree To Terms</title>
      </Helmet>
      <Row>
        <Col md="3">
          <img src={wateringImage} className="" alt="Watering plants" style={{ width: '80%' }} />
        </Col>
        {success ? (
          <Col>
            <h1>Thank you!</h1>
            <p>Your agreements have been logged and you're ready for The Plan. We look forward to meeting with you!</p>
            <p>
              You may now close this window or go to the <Link to={ApplicationRoutes.Root}>home page</Link>.
            </p>
          </Col>
        ) : (
          <></>
        )}

        {advisor && !success ? (
          <Col md="9" className="center pt-5">
            <Form onSubmit={(e) => onAgreeToTermsSubmit(e)}>
              <h1>{AppSettings.ApplicationName} Contract</h1>
              <img
                src={advisor.profilePicUrl}
                className="circle-image headshot center small-headshot"
                alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
              />
              <h3>
                {advisor.firstName} {advisor.lastName}
                {advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ', ' : ''}
                {advisor.certifications}
              </h3>
              <Label for="state" className="state-label">
                Which state do you live in?
              </Label>
              <select id="state" name="state" className="state-search" required onChange={onStateChange} value={state!}>
                <option value="">Which state do you live in?</option>
                {StateHelper.usStatesAndTerritories.map((state) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </select>
              <p>Please read and agree to the linked documents below so we can get started with The Plan!</p>
              <Input id="agreeToTerms" name="agreeToTerms" type="checkbox" onChange={(e) => onAgreeChecked(e)} />
              &nbsp;
              {advisor.isNectarine ? (
                <Label for="agreeToTerms" className="ms-2">
                  I agree to Nectarine's
                  {getComplianceDocumentsToDisplay()
                    .filter((v) => v !== null)
                    .map((currDocument, i) => (
                      <span key={i}>
                        {' '}
                        <a href={currDocument.url} target="_blank" rel="noopener noreferrer">
                          {currDocument.displayName}
                        </a>
                        {docSeparator(i, getComplianceDocumentsToDisplay().length)}
                      </span>
                    ))}
                </Label>
              ) : (
                <></>
              )}
              {!isAuthenticated ? (
                <div>
                  <Alert color="warning" className="mt-4">
                    You are not currently logged in.
                    <br />
                    Please
                    <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}`}> log in</Link> or
                    <Link to={`/${ApplicationRoutes.Register}`}> create a new account</Link> to continue.
                  </Alert>
                </div>
              ) : (
                <></>
              )}
              {errorSection.show ? (
                <Alert color="danger" className="mt-4">
                  {errorSection.text}
                </Alert>
              ) : (
                <></>
              )}
              <p className="center mt-4">
                <Button disabled={!isAgreeButtonEnabled()} color="primary" size="lg" className="schedule-btn">
                  I Agree
                </Button>
              </p>
            </Form>
          </Col>
        ) : (
          <></>
        )}

        {!advisor && advisors ? (
          <Col>
            <h1>Choose Your Advisor</h1>
            <ul>
              {advisors.map((advisor) => (
                <li key={advisor.id}>
                  <a href="#" onClick={(e) => onAdvisorClick(e, advisor.slug)}>
                    {advisor.firstName} {advisor.lastName}
                    {advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ', ' : ''}
                    {advisor.certifications}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        ) : (
          <></>
        )}

        {!advisor && !advisors ? (
          <Col>
            <LoadingSpinner />
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </Container>
  );
};
