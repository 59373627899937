//@ts-check
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { AdminPayments } from '../AdminPayments/AdminPayments';
import { ApplicationRoutes, AppSettings } from './../../../../constants';

export const AdminAdvisorPayments = () => {
  const getPayPeriods = () => {
    const startDate = new Date('2023-07-16T00:00:00'); // First day of first ever pay period
    const today = new Date(); // Current date
    const payPeriods = [];
    let currentDate = new Date(startDate); // Start from a copy of the start date

    while (currentDate <= today) {
      const endDate = new Date(currentDate);
      endDate.setDate(endDate.getDate() + 13); // Calculate the end date which is 13 days later
      var period = {};
      period.start = new Date(currentDate);
      period.end = endDate;
      payPeriods.push(period);
      currentDate.setDate(currentDate.getDate() + 14); // Move to the next week
    }

    // Sort weeks in descending order
    payPeriods.sort((a, b) => b.start - a.start);
    return payPeriods;
  };

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Advisor Payments</title>
      </Helmet>
      <Row>
        <Col>
          <Link to={`/${ApplicationRoutes.AdminRoutes.Root}`}>
            <i className="fa-regular fa-arrow-left back-arrow"></i> Back
          </Link>
          <h1>Advisor Payments</h1>

          <AdminPayments userTitle="Advisor" payPeriods={getPayPeriods()} />
        </Col>
      </Row>
    </Container>
  );
};
