import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Collapse, Container, Row } from 'reactstrap';
import AdminApiService from '../../../../services/api/AdminApiService';
import DateHelper from '../../../Helpers/DateHelper';
import GeneralHelper from '../../../Helpers/GeneralHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import './AdminThePlanImplementations.scss';

const AdminThePlanImplementations: React.FC = () => {
  const [thePlanInstances, setThePlanInstances] = useState<Admin_ThePlanDetail_ViewModel[] | null>(null);
  const [expandedPlanDetailIds, setExpandedPlanDetailIds] = useState<string[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getThePlanInstances();
      setThePlanInstances(result);
    };
    loadData();
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Admin / The Plan Implementations</title>
      </Helmet>

      {!thePlanInstances ? <LoadingSpinner message="Loading..." /> : <></>}

      {thePlanInstances ? (
        thePlanInstances.length ? (
          <>
            <h1>The Plan Implementations</h1>

            <div className="mt-3 thePlanImplementationListingsArea">
              {thePlanInstances?.map((currItem) => {
                return (
                  <div key={currItem.id} className="thePlanImplementationListing mb-4">
                    <Row>
                      <Col sm="4">
                        <div>
                          <b>Client:</b> {currItem.clientFirstName} {currItem.clientLastName}
                        </div>
                        <div>{currItem.clientEmail}</div>
                      </Col>

                      <Col sm="4">
                        <div>
                          <b>Advisor:</b> {currItem.advisorFullName}
                        </div>
                        <div>{currItem.fromEmail}</div>
                      </Col>

                      <Col sm="4">
                        <div>
                          <b>Date Started:</b>&nbsp;
                          {DateHelper.mediumDateFormat(currItem.createdAt)}
                        </div>

                        <div>
                          <b>Contract PDF:</b>&nbsp;
                          <a href={currItem.contractUrl} target="_blank" rel="noopener noreferrer">
                            Click here to view
                          </a>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col sm="4">
                        <div>
                          <b>Price:</b> {GeneralHelper.formatNumberAsCurrency(currItem.price)}
                        </div>

                        <div>
                          <b>Deduction:</b> {GeneralHelper.formatNumberAsCurrency(currItem.deductionPrice)}
                        </div>

                        <div>
                          <b>Charged Price:</b> {GeneralHelper.formatNumberAsCurrency(currItem.price - currItem.deductionPrice)}
                        </div>
                      </Col>

                      <Col sm="4">
                        <div>
                          <b>Stripe Invoice Status:</b> {currItem.stripeInvoiceStatus}
                        </div>
                        <div>
                          <b>Stripe Invoice ID:</b> {currItem.stripeInvoiceId}
                        </div>
                      </Col>

                      <Col sm="4">
                        <div>
                          <b>Stripe Invoice URL:</b>
                          &nbsp;
                          {currItem.stripeInvoiceUrl ? (
                            <a href={currItem.stripeInvoiceUrl} target="_blank" rel="noopener noreferrer">
                              Click here to view
                            </a>
                          ) : (
                            <span>(Unavailable)</span>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col sm="12">
                        <div className="subHeader">Welcome Email Details</div>
                      </Col>

                      <Col sm="4">
                        <div>
                          <b>From:</b> {currItem.fromEmail}
                        </div>

                        <div>
                          <b>BCC:</b> {currItem.bccEmail}
                        </div>
                      </Col>

                      <Col sm="8">
                        <div>
                          <b>Extra Information:</b> {currItem.extraInformation ?? '(None)'}
                        </div>
                      </Col>
                    </Row>

                    {currItem.thePlanDetailMeetings.length ? (
                      <Row className="mt-3">
                        <Col>
                          <div
                            className="pointer d-flex"
                            onClick={() => {
                              if (!expandedPlanDetailIds.includes(currItem.id)) {
                                setExpandedPlanDetailIds((v) => [...v, currItem.id]);
                              } else {
                                setExpandedPlanDetailIds((v) => v.filter((m) => m !== currItem.id));
                              }
                            }}
                          >
                            <div className="subHeader">Meetings ({currItem.thePlanDetailMeetings.length})</div>

                            <div className="flex-grow-1 ms-3 subHeader">
                              <i
                                className={`fa-light fa-chevron-down rotate ${expandedPlanDetailIds.includes(currItem.id) ? 'rotate-180' : ''}`}
                              ></i>
                            </div>
                          </div>
                          <Collapse isOpen={expandedPlanDetailIds.includes(currItem.id)}>
                            {currItem.thePlanDetailMeetings.map((currMeeting) => {
                              return (
                                <div key={currMeeting.id}>
                                  <Row className="mt-2">
                                    <Col sm="4">
                                      <div>
                                        <Col sm="12">
                                          <div className="d-flex">
                                            <div className="bold">Scheduled:</div>
                                            <div>&nbsp;{DateHelper.mediumDateFormat(currMeeting.startTime)}</div>
                                          </div>

                                          <div className="d-flex">
                                            <div className="bold">Status:</div>
                                            <div>&nbsp;{currMeeting.status}</div>
                                          </div>
                                        </Col>
                                      </div>
                                    </Col>

                                    <Col sm="8">
                                      <div>
                                        <Col sm="12">
                                          <div className="d-flex">
                                            <div className="bold">Zoom Meeting URL:&nbsp;</div>
                                            <a href={currMeeting.zoomJoinUrl} target="_blank" rel="noopener noreferrer">
                                              Click here to view
                                            </a>
                                          </div>

                                          <div className="d-flex">
                                            <div className="bold">Zoom Meeting ID:</div>
                                            <div>&nbsp;{currMeeting.zoomMeetingId}</div>
                                          </div>

                                          <div className="d-flex">
                                            <div className="bold">Zoom Meeting UUID:</div>
                                            <div>&nbsp;{currMeeting.zoomMeetingUuid ?? '(Meeting has not occurred yet)'}</div>
                                          </div>
                                        </Col>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </Collapse>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <h1>The Plan Implementations</h1>

            <div>There aren't any yet.</div>
          </>
        )
      ) : (
        <></>
      )}
    </Container>
  );
};

export default AdminThePlanImplementations;
