import createDOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import { NotificationsPanelMessage, useNotificationsPanelContext } from '../NotificationsPanelContext/NotificationsPanelContext';
import './NotificationsPanel.scss';

const messageTimeout = 4_000;
const messageTimeout_Long = 6_000;

const DOMPurify = createDOMPurify(window);

const NotificationPanel: React.FC = () => {
  const [displayedMessage, setDisplayedMessage] = useState<NotificationsPanelMessage | null>(null);
  // A ref that mirrors the value of the state variable above, so we can use it in code to sync things up
  // without the BS of React and stale variables
  const displayedMessageRef = useRef<NotificationsPanelMessage | null>(null);

  const [isHidingMessage, setIsHidingMessage] = useState<boolean>(false);

  const { nextMessageToShow$, dismissCurrentMessage$, dismissCurrentMessage } = useNotificationsPanelContext();

  const queuedMessages = useRef<NotificationsPanelMessage[]>([]);

  const hideMessagePromise = useRef<NodeJS.Timeout | null>(null);
  const onMessageHiddenPromise = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const allSubscriptions = new Subscription();

    // NOTE: We have to define all of our functions inside the useEffect or
    // else things become spaghetti real quick with React dependencies
    const hideCurrentMessage = () => {
      setIsHidingMessage(true);

      if (hideMessagePromise.current) {
        clearTimeout(hideMessagePromise.current);
      }

      if (onMessageHiddenPromise.current) {
        clearTimeout(onMessageHiddenPromise.current);
      }

      onMessageHiddenPromise.current = setTimeout(() => {
        setDisplayedMessage(null);
        displayedMessageRef.current = null;

        setIsHidingMessage(false);

        displayNextMessage();
      }, 500);
    };

    const displayNextMessage = () => {
      if (!queuedMessages.current.length || displayedMessageRef.current) {
        return;
      }

      const nextMessageToDisplay = queuedMessages.current.shift();

      if (!nextMessageToDisplay) {
        return;
      }

      setDisplayedMessage(nextMessageToDisplay);
      displayedMessageRef.current = nextMessageToDisplay;

      if (hideMessagePromise.current) {
        clearTimeout(hideMessagePromise.current);
      }

      if (onMessageHiddenPromise.current) {
        clearTimeout(onMessageHiddenPromise.current);
      }

      const timeoutToUse = nextMessageToDisplay.message.length < 60 ? messageTimeout : messageTimeout_Long;

      hideMessagePromise.current = setTimeout(() => {
        hideCurrentMessage();
      }, timeoutToUse);
    };

    // If a new message comes in
    allSubscriptions.add(
      nextMessageToShow$.subscribe((newMessage) => {
        queuedMessages.current.push(newMessage);

        if (!displayedMessageRef.current) {
          displayNextMessage();
        }
      })
    );

    // If a request to dismiss the current message early comes in
    allSubscriptions.add(
      dismissCurrentMessage$.subscribe(() => {
        if (hideMessagePromise.current) {
          clearTimeout(hideMessagePromise.current);
        }

        if (onMessageHiddenPromise.current) {
          clearTimeout(onMessageHiddenPromise.current);
        }

        hideMessagePromise.current = setTimeout(() => {
          hideCurrentMessage();
        });
      })
    );

    return () => {
      allSubscriptions.unsubscribe();
    };
  }, [dismissCurrentMessage$, displayedMessage, isHidingMessage, nextMessageToShow$]);

  const getCSSClassesForDisplayedMessage = (): string => {
    const returnValue: string[] = [];

    if (isHidingMessage) {
      returnValue.push('isHiding');
    }

    if (displayedMessage?.type) {
      returnValue.push(displayedMessage.type);
    }

    return returnValue.join(' ');
  };

  return displayedMessage ? (
    <div className="notificationsPanel">
      <div className={`displayedMessage isDisplayed ${getCSSClassesForDisplayedMessage()}`} onClick={dismissCurrentMessage}>
        <div className="innerArea">
          {displayedMessage.title ? <div className="messageTitle">{displayedMessage.title}</div> : <></>}

          <div className="messageText" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayedMessage.message) }}></div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default NotificationPanel;
