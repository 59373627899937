import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'reactstrap';
import AdminApiService from '../../../../services/api/AdminApiService';

const AdminRoles: React.FC = () => {
  const [roles, setRoles] = useState<Admin_UserRole_ViewModel[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getAllUserRoles();
      setRoles(result);
    };
    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Admin / Roles</title>
      </Helmet>

      <h1>Roles</h1>
      <Button href="/admin/roles/create">New Role</Button>

      <div className="mt-3"></div>

      <ul>
        {roles.map((role) => (
          <li key={role.id}>
            <a href={`/admin/roles/${role.id}/edit`}>{role.name}</a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default AdminRoles;
