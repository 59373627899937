import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import DateHelper from '../../../Helpers/DateHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

const AdminSearch: React.FC = () => {
  const location = useLocation();

  const [data, setData] = useState<Admin_Search_ViewModel | null>(null);

  const loadData = async () => {
    const params = new URLSearchParams(window.location.search);
    const searchText = params.get('q');

    if (searchText) {
      const result = await AdminApiService.search(searchText);
      setData(result);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setData(null);

    loadData();
  }, [location.search]);

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Search</title>
      </Helmet>
      <Row>
        <Col className="admin-search-page">
          {!data ? <LoadingSpinner message="Loading..." /> : <></>}

          {data && data.users ? (
            <div>
              <h2 className="mt-0">
                Found <strong>{data.users.length}</strong> Matching Users
              </h2>
              <p>(Searches name, title, certifications, email, IP address, slug, bio)</p>
              <table className="table admin-users">
                <thead>
                  <tr>
                    <th>Last</th>
                    <th>First</th>
                    <th>Email</th>
                    <th>State</th>
                    <th>
                      <i className="fa-duotone fa-peach light-blue"></i>
                    </th>
                    <th>Report</th>
                    <th>Impersonate</th>
                  </tr>
                </thead>
                <tbody>
                  {data.users.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${user.id}/edit`}>{user.formattedLastName}</Link>
                      </td>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${user.id}/edit`}>{user.formattedFirstName}</Link>
                      </td>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${user.id}/edit`}>{user.email}</Link>
                      </td>
                      <td>{user.state}</td>
                      <td>{user.isNectarine ? <i className="fa-duotone fa-peach light-blue"></i> : ''}</td>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${user.id}/report`}>Compliance Report</Link>
                      </td>
                      <td>
                        <AdminImpersonateLink userId={user.id} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}

          {data && data.events ? (
            <div>
              <h2>
                Found <strong>{data.events.length}</strong> Matching Events
              </h2>
              <p>(Searches client, email, private notes, public notes)</p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Advisor</th>
                    <th>Consumer</th>
                    <th>
                      <i className="fa-duotone fa-peach light-blue"></i>
                    </th>
                    <th>State</th>
                    <th>Intake</th>
                    <th>Created At</th>
                    <th>Start Time</th>
                    <th>Status</th>
                    <th>Links</th>
                    <th>Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {data.events.map((event) => (
                    <tr key={event.id}>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.advisorId}/edit`}>{event.advisorFullName}</Link>

                        {!event.advisorFullName && <span>{event.advisorEmail}</span>}

                        {event.advisorId && <AdminImpersonateLink userId={event.advisorId} />}
                      </td>

                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.consumerId}/edit`}>{event.consumerFullName}</Link>
                        {!event.consumerFullName && <span>{event.consumerEmail}</span>}

                        {event.consumerId && <AdminImpersonateLink userId={event.consumerId} />}
                      </td>
                      <td>{event.isNectarine && <i className="fa-duotone fa-peach light-blue"></i>}</td>
                      <td>{event.consumerState}</td>
                      <td>
                        {event.intakeForm && <i className="fa-duotone fa-check-to-slot att-icon"></i>}
                        {!event.intakeForm && <i className="fa-kit fa-solid-check-to-slot-slash missing-icon"></i>}
                      </td>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Events_Full}/${event.id}/edit`}>
                          {DateHelper.mediumDateFormat(event.createdAt)}
                        </Link>
                      </td>
                      <td>{DateHelper.mediumDateFormat(event.startTime)}</td>
                      <td>{event.status}</td>

                      <td>
                        <a href={event.location}>Zoom</a> <a href={event.rescheduleUrl}>Reschedule</a> <a href={event.cancelUrl}>Cancel</a>{' '}
                        {event.consumerId && event.advisorId && (
                          <a href={'/leaveareview/' + event.advisorId + '/' + event.consumerId}>Review Page</a>
                        )}
                      </td>

                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: event.paymentCurrency ? event.paymentCurrency : 'USD',
                          maximumFractionDigits: 0
                        }).format(event.paymentAmount)}
                        {!event.paymentSuccessful && event.paymentAmount > 0 && (
                          <span className="text-danger">
                            {' '}
                            <strong>FAILED!</strong>
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}

          {data && data.reviews ? (
            <div>
              <h2>
                Found <strong>{data.reviews.length}</strong> Matching Reviews
              </h2>
              <p>(Searches client, body, title)</p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Reviewer</th>
                    <th>Reviewee</th>
                    <th>Rating</th>
                    <th>Title</th>
                    <th>Display Name</th>
                    <th>Modified</th>
                    <td>Danger</td>
                  </tr>
                </thead>
                <tbody>
                  {data.reviews.map((review) => (
                    <tr key={review.id}>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${review.reviewerId}/edit`}>{review.reviewerFullName}</Link>
                        <AdminImpersonateLink userId={review.reviewerId} />
                      </td>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${review.revieweeId}/edit`}>{review.revieweeFullName}</Link>
                        <AdminImpersonateLink userId={review.revieweeId} />
                      </td>
                      <td>{review.rating}</td>
                      <td>{review.title}</td>
                      <td>{review.displayName}</td>
                      <td>{DateHelper.mediumDateFormat(review.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}

          {data && data.userAgreements ? (
            <div>
              <h2>
                Found <strong>{data.userAgreements.length}</strong> Matching User Agreements
              </h2>
              <p>(Searches client, IP address)</p>

              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>State</th>
                    <th>IP&nbsp;Address</th>
                    <th>Timestamp</th>
                    <th>Advisor</th>
                    <th>Documents</th>
                  </tr>
                </thead>
                <tbody>
                  {data.userAgreements.map((ua) => (
                    <tr key={ua.id}>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${ua.consumerId}/edit`}>
                          {ua.firstName} {ua.lastName}
                        </Link>{' '}
                        <AdminImpersonateLink userId={ua.consumerId} />
                      </td>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${ua.consumerId}/edit`}>{ua.emailAddress}</Link>
                      </td>
                      <td>{ua.stateAbbreviation}</td>
                      <td>{ua.ipAddress}</td>
                      <td>{new Date(ua.agreedAtTime).toLocaleString()}</td>
                      <td>
                        {ua.advisorId ? (
                          <div>
                            <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${ua.advisorId}/edit`}>
                              {ua.advisorFirstName} {ua.advisorLastName}
                            </Link>{' '}
                            <AdminImpersonateLink userId={ua.advisorId} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {ua.complianceDocuments.map((doc) => (
                          <span key={doc.id}>
                            <a href={doc.url} target="_blank" rel="noopener noreferrer">
                              {doc.displayName}
                            </a>
                            <span>, </span>
                          </span>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AdminSearch;
