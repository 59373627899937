import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Form, Input, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import RecordingsApiService from '../../../../services/api/RecordingsApiService';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import DateHelper from '../../../Helpers/DateHelper';
import LoadingDots from '../../../ui/LoadingAnimations/LoadingDots/LoadingDots';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';

const AdminEditRecording = () => {
  const { recordingID } = useParams();

  const [recording, setRecording] = useState<Admin_MeetingRecording_ViewModel | null>(null);
  const [recordingToEdit, setRecordingToEdit] = useState<Admin_EditMeetingRecording_EditModel>({
    id: '',
    eventId: ''
  });
  const [redirectToAdminList, setRedirect] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [presignedS3UrlForRecording, setPresignedS3UrlForRecording] = useState<string | null>(null);

  async function loadData() {
    const recordingData = await AdminApiService.getRecording(recordingID!);
    setRecording(recordingData);

    const presignedUrl = await RecordingsApiService.getPresignedS3UrlForRecording(recordingData.id);
    setPresignedS3UrlForRecording(presignedUrl);

    setRecordingToEdit({
      id: recordingData.id,
      eventId: recordingData.eventId
    });
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files![0]);
  };

  const handleFileUpload = async (event: React.FormEvent) => {
    event.preventDefault();

    setIsSaving(true);

    if (selectedFile) {
      const formData = new FormData();
      const url = `/api/zoom/manualuploadrecording/${recording!.id}`;
      formData.append('file', selectedFile);

      const token = await AuthorizeService.getAccessToken();

      try {
        await fetch(url, {
          method: 'POST',
          body: formData,
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });

        loadData();

        setIsSaving(false);
      } catch (error) {
        console.error(error);

        setIsSaving(false);
      } finally {
      }
    }
  };

  const onSave = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await AdminApiService.editRecording({
        id: recordingID!,
        eventId: recordingToEdit.eventId
      });

      setRedirect(true);
    } catch (error) {
      alert(error);
      console.error('Unable to add item.', error);
    }

    return false;
  };

  function onEventIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRecordingToEdit((v) => ({
      ...v,
      eventId: e.target.value as string
    }));
  }

  if (redirectToAdminList) {
    return <Navigate to={`/${ApplicationRoutes.AdminRoutes.Recordings_Full}`} />;
  }

  return (
    <Container>
      <Helmet>
        <title>Edit Recording</title>
      </Helmet>
      <Row>
        <Col>
          <Link to={`/${ApplicationRoutes.AdminRoutes.Recordings_Full}`}>
            <i className="fa-regular fa-arrow-left back-arrow"></i> Back
          </Link>
          <h1>Edit Recording</h1>

          {!recording ? <LoadingSpinner message="Loading..." /> : <></>}

          {recording ? (
            <Form onSubmit={onSave}>
              <Input id="id" name="id" type="hidden" value={recording.id} />
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <strong>Event ID</strong>
                    </td>
                    <td>
                      <Input
                        id="eventId"
                        name="eventId"
                        placeholder="Long EventID goes here"
                        type="text"
                        value={recordingToEdit.eventId}
                        onChange={(e) => onEventIdChange(e)}
                      />
                    </td>
                    {recordingToEdit.eventId && (
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Events_Full}/${recordingToEdit.eventId}/edit`}>
                          {recording.advisorEmail} + {recording.consumerEmail}
                        </Link>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>
                      <strong>Start Time</strong>
                    </td>
                    <td>{DateHelper.mediumDateFormat(recording.startTime)}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Duration</strong>
                    </td>
                    <td>{recording.durationInMinutes} minutes</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Size</strong>
                    </td>
                    <td>{(recording.fileSize / 1024.0 / 1024.0).toFixed(1)}MB</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>File Extension</strong>
                    </td>
                    <td>{recording.fileExtension}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Host Email</strong>
                    </td>

                    <td>{recording.hostEmail}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Status</strong>
                    </td>
                    <td>{recording.status}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Links</strong>
                    </td>

                    <td>
                      {presignedS3UrlForRecording ? (
                        <>
                          <a href={recording.downloadUrl} title="Download">
                            <i className="fa-regular fa-download"></i>
                          </a>
                          &nbsp;
                          <a className="mx-2" href={recording.playUrl} title="Play">
                            <i className="fa-regular fa-play"></i>
                          </a>
                          &nbsp;
                          <a href={presignedS3UrlForRecording} title="S3">
                            <i className="fa-regular fa-database"></i>
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button color="primary">Save</Button>
            </Form>
          ) : (
            <></>
          )}

          <hr />
          <h1>Upload a New Video</h1>
          <p>
            Uploading a video using the button below will replace the existing video if it exists and update the status. Not that this{' '}
            <span className="text-danger">does not</span> update the duration, file size, extension, etc. It's to be used only to move the
            correct Zoom file to S3. This may take like 5+ minutes for a 400MBish file depending on your internet speed.
          </p>

          <div>
            <Form onSubmit={handleFileUpload}>
              <Input type="file" onChange={handleFileSelect} />

              <Button color="primary" className="btn-lg">
                {isSaving ? <LoadingDots /> : 'Upload'}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminEditRecording;
