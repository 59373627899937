import DateHelper from '../Helpers/DateHelper';

interface ZoomMeetingSummaryProps {
  meetingSummary: Zoom_MeetingSummary_ViewModel | null;
}

const ZoomMeetingSummary: React.FC<ZoomMeetingSummaryProps> = (props: ZoomMeetingSummaryProps) => {
  if (!props.meetingSummary) {
    return <></>;
  }

  return (
    <>
      <h3>Zoom Meeting Summary</h3>

      <div className="mt-2 bold">
        {props.meetingSummary.summary_Title} ({DateHelper.shortDateFormat(props.meetingSummary.meeting_Start_Time)})
      </div>

      <div className="mt-3 bold">Quick recap</div>

      <div className="mt-1">{props.meetingSummary.summary_Overview}</div>

      {props.meetingSummary.next_Steps.length ? (
        <div>
          <div className="mt-3 bold font-l">Next Steps </div>

          <div className="mt-1">
            <ul>
              {props.meetingSummary.next_Steps.map((currStep) => {
                return <li key={currStep}>{currStep}</li>;
              })}
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}

      {props.meetingSummary.summary_Details.length ? (
        <div>
          <div className="mt-3 bold font-l2">Summary </div>

          <div className="mt-1">
            {props.meetingSummary.summary_Details.map((currDetail) => {
              return (
                <div className="mb-3" key={currDetail.label}>
                  <div className="bold">{currDetail.label}</div>
                  <div>{currDetail.summary}</div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ZoomMeetingSummary;
