import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

export default class UsersApiService extends ApiServiceBase {
  private static className = 'UsersApiService';

  public static async register(editModel: Users_Register_EditModel): Promise<void> {
    try {
      await this.makeApiCall(() => HttpHelper.post(`api/home/register`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - register()`,
        `An error occurred while registering a user: ${editModel.firstName} ${editModel.lastName}`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getBasicUserInformation(userId: string): Promise<User_BasicDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<User_BasicDetails_ViewModel>(() => HttpHelper.get(`api/home/users/${userId}`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getBasicUserInformation()`,
        `An error occurred while loading user information for user ID '${userId}'.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorComplianceDocument_ThePlan(thePlanDetailId: string): Promise<Advisor_ComplianceDocument_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisor_ComplianceDocument_ViewModel>(() =>
        HttpHelper.get(`api/users/get-advisor-compliance-document/the-plan/${thePlanDetailId}`)
      );
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorComplianceDocument_ThePlan()`,
        `An error occurred while loading the compliance document for the plan detail ID: '${thePlanDetailId}'.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async agreeToPlanTerms(advisorUserId: string, editModel: Users_AgreeToTerms_EditModel): Promise<void> {
    try {
      await this.makeApiCall(() => HttpHelper.post(`api/users/agreetoterms/${advisorUserId}`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - agreeToPlanTerms()`,
        `An error occurred while agreeing to terms for advisor ID: ${advisorUserId}`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async contactUs(name: string, email: string, message: string): Promise<void> {
    try {
      const editModel = {
        name,
        email,
        message
      };
      await this.makeApiCall(() => HttpHelper.post(`api/home/contact`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - contactUs()`, `An error occurred while submitting the contact form.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
