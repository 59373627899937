import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnStrikeThrough,
  ContentEditableEvent,
  Editor,
  EditorProvider,
  HtmlButton,
  Separator,
  Toolbar
} from 'react-simple-wysiwyg';
import { Alert, Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import sanitizeHtml from 'sanitize-html';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import { useGeneralContext } from '../../../../../services/GeneralContext';
import DateHelper from '../../../../Helpers/DateHelper';
import ErrorsHelper from '../../../../Helpers/ErrorsHelper';
import { useConfirmationOverlayContext } from '../../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import LoadingDots from '../../../../ui/LoadingAnimations/LoadingDots/LoadingDots';

interface EnterMeetingNotesModalProps {
  show: boolean;
  toggle: () => void;
  save: () => void;
  event: Advisor_Event_ViewModel_Base;
}

const EnterMeetingNotesModal: React.FC<EnterMeetingNotesModalProps> = (props: EnterMeetingNotesModalProps) => {
  const [eventToEdit, setEventToEdit] = useState<Advisor_Event_EditModel>({
    id: '',
    privateNotes: '',
    publicNotes: '',
    lastUpdated: new Date()
  });
  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  const { cachedDataRef } = useGeneralContext();

  useEffect(() => {
    const previouslyEditedEventData = getStoredEvent(props.event.id);

    setEventToEdit({
      id: props.event.id,
      lastUpdated: previouslyEditedEventData?.lastUpdated ?? props.event.notesUpdated,
      privateNotes: previouslyEditedEventData?.privateNotes ?? props.event.privateNotes,
      publicNotes: previouslyEditedEventData?.publicNotes ?? props.event.publicNotes
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getStoredEvent(id: string): Advisor_Event_EditModel | null {
    return (cachedDataRef.current[id] as Advisor_Event_EditModel) ?? null;
  }

  function saveEventToLocal(newEventData: Advisor_Event_EditModel) {
    cachedDataRef.current[eventToEdit.id] = { ...newEventData };
  }

  function clearCachedEventEditData() {
    if (cachedDataRef.current[eventToEdit.id]) {
      delete cachedDataRef.current[eventToEdit.id];
    }
  }

  function stripStyles(html: string) {
    html = addTargetBlankToLinks(html);

    // Allow only a super restricted set of tags and attributes
    return sanitizeHtml(html, {
      allowedTags: ['b', 'i', 'u', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'br', 'div', 'strike'],
      allowedAttributes: {
        a: ['href', 'target']
      }
    });
  }

  function addTargetBlankToLinks(html: string) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const anchorElements = tempDiv.querySelectorAll('a');

    anchorElements.forEach((anchor) => {
      if (!anchor.hasAttribute('target')) {
        anchor.setAttribute('target', '_blank');
      }
    });

    return tempDiv.innerHTML;
  }

  function previewMode() {
    // Strip styles from the notes strings
    setEventToEdit((v) => ({
      ...v,
      publicNotes: stripStyles(v.publicNotes),
      privateNotes: stripStyles(v.privateNotes)
    }));

    setIsPreviewMode(true);
  }

  function onFormChange(e: ContentEditableEvent, propertyName: keyof Advisor_Event_EditModel) {
    hideError();

    const newEventData: Advisor_Event_EditModel = {
      ...eventToEdit,
      [propertyName]: e.target.value
    };

    setEventToEdit(newEventData);

    saveEventToLocal(newEventData);
  }

  const saveNotes = async (e: React.MouseEvent, type: 'save' | 'save-and-send') => {
    e.preventDefault();
    e.stopPropagation();

    const sendToServer = async () => {
      try {
        setSaveLoading(true);

        await AdvisorsApiService.saveMeetingNotes(eventToEdit, type === 'save-and-send');

        props.save();

        clearCachedEventEditData();

        closeModal();
      } catch (error) {
        showError(ErrorsHelper.getErrorMessageFromErrorObject(error));
      } finally {
        setSaveLoading(false);
      }
    };

    if (type === 'save-and-send') {
      showConfirmationOverlay({
        text: 'Are you sure you want to save and send the email to the client?',
        yesButtonText: 'Save and Send',
        noButtonText: 'Not Yet',
        yesAction: async () => {
          await sendToServer();
        }
      });
    } else {
      await sendToServer();
    }
  };

  function showError(text: string) {
    setErrorSection({ show: true, text });
  }

  function hideError() {
    setErrorSection({ show: false, text: null });
  }

  function closeModal() {
    setIsPreviewMode(false);
    props.toggle();
  }

  return (
    <Modal isOpen={props.show} toggle={closeModal}>
      {event && (
        <Form>
          <ModalHeader toggle={closeModal}>
            Meeting Notes for <strong>{props.event.consumerFullName}</strong> on{' '}
            <strong>{DateHelper.mediumDateFormat(props.event.startTime)}</strong>
          </ModalHeader>
          <ModalBody>
            {errorSection.show ? <Alert color="danger">{errorSection.text}</Alert> : <></>}

            {isPreviewMode ? (
              <div>
                <div className="private-notes">
                  <h3>Private notes (not visible to client):</h3>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(eventToEdit.privateNotes) }}></div>
                </div>
                {!props.event.notesSent && (
                  <div>
                    <hr />
                    <h3>Wrap-up email that will be sent to {props.event.consumerFullName}</h3>
                    <p className="email-header">
                      <strong>To:</strong> {props.event.consumerFullName} &lt;{props.event.consumerEmail}&gt;
                      <br />
                      <strong>From:</strong> Nectarine Team &lt;team@hellonectarine.com&gt;
                      <br />
                      <strong>Bcc:</strong> {props.event.advisorFullName} &lt;{props.event.advisorEmail}&gt;
                      <br />
                    </p>
                    <hr className="hr-light" />
                    <p className="email-header">
                      <strong>Subject:</strong> Your Nectarine Meeting Notes from {props.event.advisorFullName} -{' '}
                      {DateHelper.shortDateFormat(props.event.startTime)}
                      <br />
                    </p>
                    <hr className="hr-light" />
                    <p>Hi {props.event.consumerFirstName},</p>
                    <p>
                      Thanks so much for meeting with Nectarine! Below are notes and takeaways from your advisor,{' '}
                      {props.event.advisorFullName}:
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(eventToEdit.publicNotes, { ADD_ATTR: ['target'] }) }}></p>
                    <hr />
                    <p>How was your meeting with {props.event.advisorFirstName}? Please take a moment to leave a review.</p>
                    <p>
                      <a className="btn btn-light">Leave a review for {props.event.advisorFirstName}</a>
                    </p>
                    <p>Do you have more questions or want to follow up with {props.event.advisorFirstName}?</p>
                    <p>
                      <a className="btn btn-light">Book another meeting with {props.event.advisorFirstName}</a>
                    </p>

                    <p>Until next time!</p>
                    <p>
                      Nectarine Team
                      <br />
                      <a href="https://hellonectarine.com">hellonectarine.com</a>
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}

            {!isPreviewMode ? (
              <div>
                <FormGroup>
                  <Label for="privateNotes">Private Notes (Not Visible to Client)</Label>
                  <EditorProvider>
                    <Editor
                      id="privateNotes"
                      name="privateNotes"
                      value={eventToEdit.privateNotes}
                      containerProps={{ style: { minHeight: '200px' } }}
                      onChange={(e) => onFormChange(e, 'privateNotes')}
                      {...{
                        required: true,
                        placeholder: 'e.g. ' + props.event.consumerFirstName + ' is 35 years old and works as a teacher....'
                      }}
                    >
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnStrikeThrough />
                        <Separator />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <Separator />
                        <BtnLink />
                        <HtmlButton />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                </FormGroup>
                {!props.event.notesSent ? (
                  <FormGroup>
                    <Label for="publicNotes">Meeting Notes (Shared With Client)</Label>
                    <EditorProvider>
                      <Editor
                        id="publicNotes"
                        name="publicNotes"
                        value={eventToEdit.publicNotes}
                        containerProps={{ style: { minHeight: '200px' } }}
                        onChange={(e) => onFormChange(e, 'publicNotes')}
                        {...{
                          required: true,
                          placeholder:
                            'e.g. It was great talking with you today, ' +
                            props.event.consumerFirstName +
                            '! Here are your next steps and resources we discussed...'
                        }}
                      >
                        <Toolbar>
                          <BtnBold />
                          <BtnItalic />
                          <BtnStrikeThrough />
                          <Separator />
                          <BtnBulletList />
                          <BtnNumberedList />
                          <Separator />
                          <BtnLink />
                          <HtmlButton />
                        </Toolbar>
                      </Editor>
                    </EditorProvider>
                  </FormGroup>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </ModalBody>
          <ModalFooter>
            {!isPreviewMode ? (
              <div className="d-flex gap-3">
                <Button color="light" onClick={closeModal}>
                  Cancel
                </Button>

                <Button
                  color="primary"
                  onClick={() => {
                    previewMode();
                    return false;
                  }}
                >
                  Preview Notes
                </Button>
              </div>
            ) : (
              <></>
            )}

            {isPreviewMode ? (
              <div>
                <div className="d-flex gap-3">
                  {!saveLoading ? (
                    <span>
                      <Button
                        color="light"
                        onClick={() => {
                          setIsPreviewMode(false);
                          return false;
                        }}
                      >
                        Back
                      </Button>{' '}
                    </span>
                  ) : (
                    <></>
                  )}

                  <Button
                    color="light"
                    disabled={saveLoading}
                    onClick={(e) => {
                      saveNotes(e, 'save');
                      return false;
                    }}
                  >
                    {saveLoading ? <LoadingDots /> : 'Save Notes Without Sending'}
                  </Button>

                  {!props.event.notesSent ? (
                    <Button
                      color="primary"
                      disabled={saveLoading}
                      onClick={(e) => {
                        saveNotes(e, 'save-and-send');
                        return false;
                      }}
                    >
                      {saveLoading ? <LoadingDots /> : 'Save and Send Email To Client'}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export default EnterMeetingNotesModal;

function getStoredEventKey(id: string) {
  return 'EventNotes-' + id;
}
