import { useEffect, useState } from 'react';
import { Alert, Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import DateHelper from '../../../../Helpers/DateHelper';
import ErrorsHelper from '../../../../Helpers/ErrorsHelper';
import LoadingDots from '../../../../ui/LoadingAnimations/LoadingDots/LoadingDots';

interface AdvisorEditBioProps {
  show: boolean;
  toggle: () => void;
  advisor: Advisor_BasicDetails_ViewModel;
  save: (updatedAdvisorVM: Advisor_BasicDetails_ViewModel) => void;
}

const AdvisorEditBio: React.FC<AdvisorEditBioProps> = (props: AdvisorEditBioProps) => {
  const [saveBioLoading, setSaveBioLoading] = useState(false);
  const [advisorToEdit, setAdvisorToEdit] = useState<Advisor_EditAdvisor_EditModel>({ ...props.advisor });
  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });

  const [experienceStartString, setExperienceStartString] = useState<string>();

  useEffect(() => {
    setExperienceStartString(advisorToEdit.experienceStart.toLocaleDateString('en-CA'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onFormChange(e: React.ChangeEvent<HTMLInputElement>, propertyName: keyof Advisor_EditAdvisor_EditModel) {
    hideError();
    setAdvisorToEdit((a) => ({
      ...a,
      [propertyName]: e.target.value
    }));
  }

  const onSetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newDate = advisorToEdit.experienceStart;

    if (DateHelper.isValidDate(e.target.value)) {
      newDate = new Date(e.target.value);
    }

    setAdvisorToEdit((a) => ({
      ...a,
      experienceStart: newDate
    }));

    setExperienceStartString(newDate.toLocaleDateString('en-CA'));
  };

  const onSave = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setSaveBioLoading(true);

      await AdvisorsApiService.updateAdvisorBioOrRates(advisorToEdit);

      props.save({
        ...props.advisor,
        experienceStart: advisorToEdit.experienceStart,
        personalBio: advisorToEdit.personalBio,
        longBio: advisorToEdit.longBio
      });
    } catch (error) {
      showError(ErrorsHelper.getErrorMessageFromErrorObject(error));
    } finally {
      setSaveBioLoading(false);
    }

    return false;
  };

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function hideError() {
    setErrorSection({ show: false, text: null });
  }

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <Form onSubmit={onSave}>
        <ModalHeader toggle={props.toggle}>Edit Bio</ModalHeader>
        <ModalBody>
          {errorSection.show ? <Alert color="danger">{errorSection.text}</Alert> : <></>}

          <Label>Experience Start Date</Label>
          <Input
            id="experienceStartString"
            name="experienceStartString"
            type="date"
            style={{ width: '150px' }}
            value={experienceStartString}
            onChange={(e) => onSetDate(e)}
          />

          <Label className="mt-2">Bio (1,000 max characters)</Label>
          <Input
            id="longBio"
            name="LongBio"
            type="textarea"
            rows="5"
            required
            maxLength={1000}
            placeholder="e.g. Hi, I’m Shane! I'm such a personal finance nerd that I kept a budget on our family trips at age 7. After working at a Wall Street firm for eight years, I started my own financial planning firm and also became a personal finance content creator. My main goal is to make you feel more confident with your money. And I will always encourage you to pursue your dreams, whether it is being a successful entrepreneur or achieving financial independence. I’d love to learn about your story, how you got to where you are today, and how I can help!"
            value={advisorToEdit.longBio}
            onChange={(e) => onFormChange(e, 'longBio')}
          />

          <Label className="mt-2">Personal Stuff (500 max characters)</Label>
          <Input
            id="personalBio"
            name="personalBio"
            type="textarea"
            rows="5"
            maxLength={500}
            placeholder="e.g. I’ve lived all over the US - from LA to NYC, Boston, SF, Austin, and Chicago. But this guy likes my backyard in the suburbs so I guess I’m staying put."
            value={advisorToEdit.personalBio}
            onChange={(e) => onFormChange(e, 'personalBio')}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={saveBioLoading}>
            {saveBioLoading ? <LoadingDots /> : 'Save Bio'}
          </Button>{' '}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AdvisorEditBio;
