import { createContext, ReactNode, useContext } from 'react';
import { Observable } from 'rxjs';
import { useObservableWithBehaviorSubject } from '../../../../hooks/UseObservable';

interface LoadingOverlayContextType {
  loadingOverlaysCount$: Observable<number>;
  showLoadingOverlay: () => void;
  hideLoadingOverlay: () => void;
}

const LoadingOverlayContext = createContext<LoadingOverlayContextType | undefined>(undefined);

const LoadingOverlayContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // NOTE: We use an observable and a setter function here to expose functionality to calling code,
  // allowing things to cleanly interact with this component without triggering a rerender every time a
  // state variable changes.
  const [loadingOverlaysCount$, setLoadingOverlaysCount, getLoadingOverlaysCount] = useObservableWithBehaviorSubject<number>(0);

  const showLoadingOverlay = () => {
    setLoadingOverlaysCount(getLoadingOverlaysCount() + 1);
  };

  const hideLoadingOverlay = () => {
    setLoadingOverlaysCount(Math.max(0, getLoadingOverlaysCount() - 1));
  };

  // NOTE: We expose an observable and two methods to manipulate the underlying value of the observable.
  // Because we don't use any React state inside this code, when the underlying observable value changes, it will
  // NOT trigger a re-render of this component since all of our variable references are stable (it will
  // simply emit a value on the observable instead).
  return (
    <LoadingOverlayContext.Provider
      value={{
        loadingOverlaysCount$,
        showLoadingOverlay,
        hideLoadingOverlay
      }}
    >
      {children}
    </LoadingOverlayContext.Provider>
  );
};

const useLoadingOverlayContext = () => {
  const context = useContext(LoadingOverlayContext);
  if (!context) {
    throw new Error('useLoadingOverlayContext() must be used within an LoadingOverlayContext. Please verify your DOM structure.');
  }
  return context;
};

export { LoadingOverlayContextProvider, useLoadingOverlayContext };
