import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import DateHelper from '../../../Helpers/DateHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

export const AdminEditEvent = () => {
  const { eventID } = useParams();
  const [event, setEvent] = useState<Admin_Event_ViewModel | null>(null);
  const [eventToEdit, setEventToEdit] = useState<Admin_Event_EditModel | null>(null);
  const [redirectToAdminList, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      const eventData = await AdminApiService.getEventById(eventID!);
      setEvent(eventData);

      setEventToEdit({
        id: eventData.id,
        advisorId: eventData.advisorId,
        consumerId: eventData.consumerId,
        isNectarine: eventData.isNectarine,
        location: eventData.location,
        paymentAmount: eventData.paymentAmount,
        paymentSuccessful: eventData.paymentSuccessful,
        status: eventData.status
      });
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await AdminApiService.updateEvent(eventToEdit!);
      setRedirect(true);
    } catch (error) {
      alert(error);
      console.error('Unable to add item.', error);
    }

    return false;
  };

  function onFormChange(e: React.ChangeEvent<HTMLInputElement>, propertyName: keyof Admin_Event_EditModel) {
    setEventToEdit((v) => {
      return {
        ...v!,
        [propertyName]: e.target.value
      };
    });
  }

  const onIsNectarineValueChange = () => {
    setEventToEdit((a) => ({
      ...a!,
      isNectarine: !a!.isNectarine
    }));
  };

  if (redirectToAdminList) {
    return <Navigate to={`/${ApplicationRoutes.AdminRoutes.Root}`} />;
  }

  return (
    <Container>
      <Helmet>
        <title>Edit Event</title>
      </Helmet>
      <Row>
        <Col>
          <h1>Edit Event</h1>
          <p>
            <Link to={`/${ApplicationRoutes.AdminRoutes.Events_Full}`}>&lt;- Back to all events</Link>
          </p>

          {!eventToEdit ? <LoadingSpinner message="Loading..." /> : <></>}

          {event && eventToEdit ? (
            <Form onSubmit={onSave}>
              <Input id="id" name="id" type="hidden" value={eventToEdit.id} />
              <table className="table">
                <tr>
                  <td>
                    <strong>Advisor ID</strong>
                  </td>
                  <td>
                    <Input
                      id="advisorID"
                      name="advisorID"
                      placeholder="Long ID goes here"
                      type="text"
                      value={eventToEdit.advisorId!}
                      onChange={(e) => onFormChange(e, 'advisorId')}
                    />
                  </td>

                  {event.advisorFullName ? (
                    <td>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.advisorId}/edit`}>{event.advisorFullName}</Link>
                      <AdminImpersonateLink userId={event.advisorId!} />
                    </td>
                  ) : (
                    <></>
                  )}

                  {!event.advisorFullName ? <td>{event.advisorEmail}</td> : <></>}
                </tr>

                <tr>
                  <td>
                    <strong>Consumer ID</strong>
                  </td>
                  <td>
                    <Input
                      id="consumerID"
                      name="consumerID"
                      placeholder="Long ID goes here"
                      type="text"
                      value={eventToEdit.consumerId!}
                      onChange={(e) => onFormChange(e, 'consumerId')}
                    />
                  </td>

                  {event.consumerFullName && (
                    <td>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.consumerId}/edit`}>{event.consumerFullName}</Link>

                      <AdminImpersonateLink userId={event.consumerId!} />
                    </td>
                  )}

                  {!event.consumerFullName ? <td>{event.consumerEmail}</td> : <></>}
                </tr>
                <tr>
                  <td>
                    <strong>Created At</strong>
                  </td>

                  <td>
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Events_Full}/${event.id}/edit`}>
                      {DateHelper.mediumDateFormat(event.createdAt)}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Start Time</strong>
                  </td>

                  <td>{DateHelper.mediumDateFormat(event.startTime)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Status</strong>
                  </td>
                  <td>
                    <Input id="status" name="status" type="select" onChange={(e) => onFormChange(e, 'status')} value={eventToEdit.status}>
                      <option value="">Choose a status...</option>
                      <option value="Active">Active</option>
                      <option value="Scheduled">Scheduled</option>
                      <option value="Canceled">Canceled</option>
                      <option value="Completed">Completed</option>
                      <option value="Refunded">Refunded</option>
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Client Paid Amount</strong>
                  </td>
                  <td>
                    <Input
                      id="paymentAmount"
                      name="paymentAmount"
                      type="text"
                      value={eventToEdit.paymentAmount}
                      onChange={(e) => onFormChange(e, 'paymentAmount')}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Payment Successful</strong>
                  </td>
                  <td>
                    <Input
                      id="paymentSuccessful"
                      name="paymentSuccessful"
                      type="checkbox"
                      checked={eventToEdit.paymentSuccessful}
                      onChange={(e) => onFormChange(e, 'paymentSuccessful')}
                    />

                    {!eventToEdit.paymentSuccessful && eventToEdit.paymentAmount > 0 && (
                      <span className="text-danger">
                        <strong>FAILED!</strong>
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Zoom Link</strong>
                  </td>
                  <td>
                    <Input
                      id="location"
                      name="location"
                      type="text"
                      value={eventToEdit.location}
                      onChange={(e) => onFormChange(e, 'location')}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-duotone fa-peach light-blue"></i>
                  </td>
                  <td>
                    <Label>
                      <Input
                        id="isNectarine"
                        name="isNectarine"
                        type="checkbox"
                        checked={eventToEdit.isNectarine}
                        onChange={() => onIsNectarineValueChange()}
                      />
                      &nbsp;&nbsp;
                      <i className={'fa-duotone fa-peach' + (eventToEdit.isNectarine ? ' light-blue' : '')}></i>
                      &nbsp;<strong>Is Nectarine</strong> (When checked this event is operating under the Nectarine RIA)
                    </Label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Links</strong>
                  </td>

                  <td>
                    <a href={event.location}>Zoom</a> <a href={event.rescheduleUrl}>Reschedule</a> <a href={event.cancelUrl}>Cancel</a>{' '}
                    {event.consumerFullName && event.advisorFullName ? (
                      <a href={'/leaveareview/' + event.advisorId + '/' + event.consumerId}>Review Page</a>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </table>
              <Button color="primary">Save</Button>
            </Form>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
};
