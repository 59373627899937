import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import DateHelper from '../../../Helpers/DateHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

export const AdminReviews: React.FC = () => {
  const [reviews, setReviews] = useState<Reviews_Review_ViewModel[]>([]);

  React.useEffect(() => {
    const loadData = async () => {
      const reviewsData = await AdminApiService.getAllReviews();
      setReviews(reviewsData);
    };

    loadData();
  }, []);

  const onDelete = async (event: React.MouseEvent, review: Reviews_Review_ViewModel) => {
    event.preventDefault();

    const isConfirmed = window.confirm("Are you sure you want to delete this review? This is permanent and can't be undone.");

    if (!isConfirmed) {
      return false;
    }

    const updatedReviews = [...reviews];
    const index = updatedReviews.indexOf(review);
    if (index !== -1) {
      updatedReviews.splice(index, 1);
    }

    await AdminApiService.deleteReview(review.id);

    setReviews(updatedReviews);

    return false;
  };

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Reviews</title>
      </Helmet>
      <Row>
        <Col>
          <h1>All Reviews</h1>

          {!reviews && <LoadingSpinner message="Loading..." />}

          {reviews && (
            <p>
              Showing <strong>{reviews.length}</strong> results
            </p>
          )}

          {reviews && (
            <table className="table">
              <thead>
                <tr>
                  <th>Reviewer</th>
                  <th>Reviewee</th>
                  <th>Rating</th>
                  <th>Coverage Rating</th>
                  <th>Competence Rating</th>
                  <th>Recap Rating</th>
                  <th>Recommendation Rating</th>
                  <th>Title</th>
                  <th>Display Name</th>
                  <th>Modified</th>
                  <td>Danger</td>
                </tr>
              </thead>
              <tbody>
                {reviews.map((review) => (
                  <tr key={review.id}>
                    <td>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${review.reviewerId}/edit`}>{review.reviewerFullName}</Link>
                      <AdminImpersonateLink userId={review.reviewerId} />
                    </td>
                    <td>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${review.revieweeId}/edit`}>{review.revieweeFullName}</Link>
                      <AdminImpersonateLink userId={review.revieweeId} />
                    </td>
                    <td>{review.rating}</td>
                    <td>{review.coverageRating}</td>
                    <td>{review.competenceRating}</td>
                    <td>{review.recapRating}</td>
                    <td>{review.recommendationRating}</td>
                    <td>{review.title}</td>
                    <td>{review.displayName}</td>
                    <td>{DateHelper.mediumDateFormat(review.createdAt)}</td>
                    <td>
                      <Button color="danger" onClick={(event) => onDelete(event, review)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    </Container>
  );
};
