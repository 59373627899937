import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

export default class AdvisorsApiService extends ApiServiceBase {
  private static className = 'AdvisorsApiService';

  public static async getAllAdvisorDetails(queryStringParams?: string): Promise<Advisor_BasicDetails_ViewModel[]> {
    const url = `api/AdvisorResults/get-advisors-details${queryStringParams ?? ''}`;

    try {
      const result = await this.makeApiCall<Advisor_BasicDetails_ViewModel[]>(() => HttpHelper.get(url));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAllAdvisorDetails()`,
        `An error occurred while loading advisors details: ${url}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorDetailsByIdOrSlug(
    idOrSlug: string,
    showAttributes: boolean,
    showDetails: boolean,
    showReviewDetails: boolean
  ): Promise<Advisor_BasicDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisor_BasicDetails_ViewModel>(() =>
        HttpHelper.get(`api/users/slug/${showAttributes}/${showDetails}/${showReviewDetails}/${idOrSlug}`)
      );
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorDetailsBySlug()`,
        `An error occurred while loading advisor details with slug: ${idOrSlug}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorsSharedAttributes(): Promise<Advisor_BasicDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisor_BasicDetails_ViewModel>(() => HttpHelper.get(`api/advisor/sharedattributes`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorsSharedAttributes()`,
        `An error occurred while setting an advisor's shared attributes.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async setAdvisorsSharedAttributes(newAttributes: string[]): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/sharedattributes`, newAttributes));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - setAdvisorsSharedAttributes()`,
        `An error occurred while setting an advisor's shared attributes.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorsToReview(): Promise<User_BasicDetails_ViewModel[]> {
    try {
      const result = await this.makeApiCall<User_BasicDetails_ViewModel[]>(() => HttpHelper.get(`api/reviews/advisors-to-review`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAdvisorsToReview()`, `An error occurred while loading advisors to review.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorReviews(): Promise<Reviews_Review_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Reviews_Review_ViewModel[]>(() => HttpHelper.get(`api/advisor/reviews`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorReviews()`,
        `An error occurred while loading reviews for the advisor.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorClientInfo(clientId: string): Promise<Advisor_ClientDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisor_ClientDetails_ViewModel>(() => HttpHelper.get(`api/advisor/client/${clientId}`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorClientInfo()`,
        `An error occurred while loading advisor client data for client ID: ${clientId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorClients(): Promise<Advisor_ClientDetails_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Advisor_ClientDetails_ViewModel[]>(() => HttpHelper.get(`api/advisor/clients`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAdvisorClients()`, `An error occurred while loading advisor clients data.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async checkThePlanCalendlyLink(): Promise<boolean> {
    try {
      const result = await this.makeApiCall<boolean>(() => HttpHelper.get(`api/advisor/check-the-plan-calendly-link`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - checkThePlanCalendlyLink()`,
        `An error occurred while checking the Calendly link for The Plan.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateAdvisor(editModel: Advisor_EditAdvisor_EditModel): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/update-advisor`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - updateAdvisor()`, `An error occurred while updating advisor information.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateAdvisorBioOrRates(editModel: Advisor_EditAdvisor_EditModel): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/update-advisor-2`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - updateAdvisorBioOrRates()`,
        `An error occurred while updating the advisor bio or hourly rate.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateAdvisorFinancialPlanningLinks(editModel: Advisor_EditAdvisor_EditModel): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/update-advisor-financial-links`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - updateAdvisorFinancialPlanningLinks()`,
        `An error occurred while updating the advisor financial planning links.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getDashboardData(): Promise<Advisors_DashboardPage_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisors_DashboardPage_ViewModel>(() => HttpHelper.get(`api/advisor/dashboard`));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getDashboardData()`, 'An error occurred while loading dashboard data.', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async saveMeetingNotes(editModel: Advisor_Event_EditModel, shouldSendMeetingNotes: boolean): Promise<void> {
    try {
      await this.makeApiCall(() =>
        HttpHelper.put(`api/advisor/${shouldSendMeetingNotes ? 'sendnotes' : 'savenotes'}/${editModel.id}`, editModel)
      );
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - saveMeetingNotes()`, 'An error occurred while saving the meeting notes.', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async sendThePlanWelcomeEmail(editModel: Advisors_InitiateThePlan_EditModel): Promise<void> {
    try {
      await this.makeApiCall(() => HttpHelper.post(`api/advisor/send-the-plan-welcome-email`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - sendThePlanWelcomeEmail()`,
        'An error occurred while sending the plan welcome email.',
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
