import { cloneDeep } from 'lodash';
import React, { ReactNode, useEffect } from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { AppRoutesConfiguration, getMissingAppRouteComponents, setAppRouteComponent } from './AppRouteDefinitions';
import AdvisorLayout from './components/Advisor/AdvisorLayout';
import { Login } from './components/api-authorization/Login';
import Logout from './components/api-authorization/Logout';
import About from './components/Pages/About/About';
import { AdvisorAccountClient } from './components/Pages/Account/Advisor/AdvisorAccountClient/AdvisorAccountClient';
import { AdvisorAccountComplianceReport } from './components/Pages/Account/Advisor/AdvisorAccountComplianceReport/AdvisorAccountComplianceReport';
import { AdvisorAccountPayments } from './components/Pages/Account/Advisor/AdvisorAccountPayments/AdvisorAccountPayments';
import { AdvisorAccountProfile } from './components/Pages/Account/Advisor/AdvisorAccountProfile/AdvisorAccountProfile';
import { AdvisorAccountReviews } from './components/Pages/Account/Advisor/AdvisorAccountReviews/AdvisorAccountReviews';
import { AdvisorIndex } from './components/Pages/Account/Advisor/Root/AdvisorIndex';
import { ClientAccountAffiliate } from './components/Pages/Account/Client/ClientAccountAffiliate/ClientAccountAffiliate';
import { ClientAccountReviews } from './components/Pages/Account/Client/ClientAccountReviews/ClientAccountReviews';
import AccountLayout from './components/Pages/Account/Client/Layout/Layout';
import { AccountIndex } from './components/Pages/Account/Client/Root/AccountIndex';
import { AccountRegister } from './components/Pages/Account/Register/AccountRegister';
import { AdvisorAttributes } from './components/Pages/Admin/AdminAdvisorAttributes/AdminAdvisorAttributes';
import { AdminComplianceReport } from './components/Pages/Admin/AdminComplianceReport/AdminComplianceReport';
import { AdminCreateRole } from './components/Pages/Admin/AdminCreateRole/AdminCreateRole';
import { AdminDocuments } from './components/Pages/Admin/AdminDocuments/AdminDocuments';
import { AdminEditEvent } from './components/Pages/Admin/AdminEditEvent/AdminEditEvent';
import AdminEditRecording from './components/Pages/Admin/AdminEditRecording/AdminEditRecording';
import { AdminEditRole } from './components/Pages/Admin/AdminEditRole/AdminEditRole';
import { AdminEditUser } from './components/Pages/Admin/AdminEditUser/AdminEditUser';
import { AdminEvents } from './components/Pages/Admin/AdminEvents/AdminEvents';
import AdminFeatureFlags from './components/Pages/Admin/AdminFeatureFlags/AdminFeatureFlags';
import { AdminIntakeForms } from './components/Pages/Admin/AdminIntakeForms/AdminIntakeForms';
import { AdminAdvisorPayments } from './components/Pages/Admin/AdminPayments_Advisor/AdminPayments_Advisor';
import { AdminAffiliatePayments } from './components/Pages/Admin/AdminPayments_Affiliate/AdminPayments_Affiliate';
import AdminRecordings from './components/Pages/Admin/AdminRecordings/AdminRecordings';
import { AdminReports } from './components/Pages/Admin/AdminReports/AdminReports';
import { AdminReviews } from './components/Pages/Admin/AdminReviews/AdminReviews';
import AdminRoles from './components/Pages/Admin/AdminRoles/AdminRoles';
import AdminSearch from './components/Pages/Admin/AdminSearch/AdminSearch';
import AdminThePlanImplementations from './components/Pages/Admin/AdminThePlanImplementations/AdminThePlanImplementations';
import { AdminUserAgreements } from './components/Pages/Admin/AdminUserAgreements/AdminUserAgreements';
import { AdminUsers } from './components/Pages/Admin/AdminUsers/AdminUsers';
import { AdminLayout } from './components/Pages/Admin/Layout/AdminLayout';
import { AdminIndex } from './components/Pages/Admin/Root/Index';
import { AdvisorDetails } from './components/Pages/Advisors/AdvisorDetails/AdvisorDetails';
import { AdvisorsResults } from './components/Pages/Advisors/AdvisorResults/AdvisorsResults';
import Affiliates from './components/Pages/Affiliates/Affiliates';
import { Booked } from './components/Pages/Booked/Booked';
import Contact from './components/Pages/Contact/Contact';
import ErrorPage from './components/Pages/Errors/Error/ErrorPage';
import NotFound from './components/Pages/Errors/NotFound/NotFound';
import Home from './components/Pages/Home/Home';
import AffiliateLandingPage from './components/Pages/LandingPages/AffiliateLandingPage';
import { LandingPage_FlatFeeFinancialPlanners } from './components/Pages/LandingPages/FlatFeeFinancialPlanners';
import LandingPage_HourlyFinancialAdvisors from './components/Pages/LandingPages/HourlyFinancialAdvisors';
import { LandingPages } from './components/Pages/LandingPages/LandingPages';
import { PageLayout } from './components/Pages/Layout/PageLayout/PageLayout';
import { LeaveAReview } from './components/Pages/LeaveAReview/LeaveAReview';
import NonUnitedStates from './components/Pages/NonUnitedStates/NonUnitedStates';
import Privacy from './components/Pages/Privacy/Privacy';
import Recruit from './components/Pages/Recruit/Recruit';
import Terms from './components/Pages/Terms/Terms/Terms';
import { ThePlanTerms } from './components/Pages/Terms/ThePlanTerms/ThePlanTerms';
import { ThePlan } from './components/Pages/ThePlan/ThePlan';
import { Redirect } from './components/Redirect/Redirect';
import { RoleRestriction } from './components/RoleRestriction/RoleRestriction';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ConfirmationOverlay from './components/ui/ConfirmationOverlay/ConfirmationOverlay';
import LoadingOverlay from './components/ui/LoadingAnimations/LoadingOverlay/LoadingOverlay';
import NotificationPanel from './components/ui/Notifications/NotificationsPanel/NotificationsPanel';
import { ApplicationRoutes, UserLoginActions, UserLogoutActions, UserRoles } from './constants';
import { useGeneralContext } from './services/GeneralContext';

const AppRoutes: React.FC = () => {
  const { initializeFeatureFlags } = useGeneralContext();

  useEffect(() => {
    const loadData = async () => {
      await initializeFeatureFlags();
    };
    loadData();
  }, [initializeFeatureFlags]);

  return (
    <div>
      <ScrollToTop />

      <LoadingOverlay />

      <ConfirmationOverlay />

      <NotificationPanel />

      <Routes>
        {routesJSX.map((currJSX, i) => {
          return <React.Fragment key={i}>{currJSX}</React.Fragment>;
        })}

        <Route path={ApplicationRoutes.Root} element={<PageLayout />}>
          <Route path="*" element={<NotFound />} /> {/* Catch-all route 404 page */}
        </Route>
      </Routes>
    </div>
  );
};

// NOTE: We define our routes structure in a separate Typescript file, which we then import into this file
// and marry up routes with JSX components.
// We do this because we generate a sitemap on build using the routes structure Typescript file, and defining
// JSX inside of that file leads to weirdness when run inside a node environment.
const appRoutesConfig = cloneDeep(AppRoutesConfiguration);
setAppRouteComponent(appRoutesConfig, 'Root', <PageLayout />);
setAppRouteComponent(appRoutesConfig, 'Home', <Home />);
setAppRouteComponent(appRoutesConfig, 'About', <About />);
setAppRouteComponent(appRoutesConfig, 'AdvisorDetails', <AdvisorDetails />);
setAppRouteComponent(appRoutesConfig, 'Advisors_SingleFilter', <AdvisorsResults />);
setAppRouteComponent(appRoutesConfig, 'Advisors_StateAndSingleFilter', <AdvisorsResults />);
setAppRouteComponent(appRoutesConfig, 'Advisors', <AdvisorsResults />);
setAppRouteComponent(appRoutesConfig, 'Affiliates', <Affiliates />);
setAppRouteComponent(appRoutesConfig, 'AffiliatesLandingPage', <AffiliateLandingPage />);
setAppRouteComponent(appRoutesConfig, 'Apply', <Redirect url="https://hellonectarine.typeform.com/advisors" />);
setAppRouteComponent(appRoutesConfig, 'Booked', <Booked />);
setAppRouteComponent(appRoutesConfig, 'Contact', <Contact />);
setAppRouteComponent(appRoutesConfig, 'Error', <ErrorPage />);
setAppRouteComponent(appRoutesConfig, 'FlatFeeFinancialPlanners', <LandingPage_FlatFeeFinancialPlanners />);
setAppRouteComponent(appRoutesConfig, 'HourlyFinancialAdvisors', <LandingPage_HourlyFinancialAdvisors />);
setAppRouteComponent(appRoutesConfig, 'LeaveAReview', <LeaveAReview />);
setAppRouteComponent(appRoutesConfig, 'NonUS', <NonUnitedStates />);
setAppRouteComponent(appRoutesConfig, 'Privacy', <Privacy />);
setAppRouteComponent(appRoutesConfig, 'Register', <AccountRegister />);
setAppRouteComponent(appRoutesConfig, 'Recruit', <Recruit />);
setAppRouteComponent(appRoutesConfig, 'Services', <LandingPages />);
setAppRouteComponent(appRoutesConfig, 'Terms', <Terms />);
setAppRouteComponent(appRoutesConfig, 'ThePlan_Terms', <ThePlanTerms />);
setAppRouteComponent(appRoutesConfig, 'ThePlan', <ThePlan />);

// Admin Routes
setAppRouteComponent(appRoutesConfig, 'AdminRoot', <RoleRestriction allowedRoles={UserRoles.Admin} element={<AdminLayout />} />);
setAppRouteComponent(appRoutesConfig, 'AdminRootIndex', <AdminIndex />);
setAppRouteComponent(appRoutesConfig, 'AdminComplianceReport', <AdminComplianceReport />);
setAppRouteComponent(appRoutesConfig, 'AdminSearch', <AdminSearch />);

setAppRouteComponent(appRoutesConfig, 'AdminUsersIndex', <AdminUsers />);
setAppRouteComponent(appRoutesConfig, 'AdminEditUser', <AdminEditUser />);

setAppRouteComponent(appRoutesConfig, 'AdminRolesIndex', <AdminRoles />);
setAppRouteComponent(appRoutesConfig, 'AdminCreateRole', <AdminCreateRole />);
setAppRouteComponent(appRoutesConfig, 'AdminEditRole', <AdminEditRole />);

setAppRouteComponent(appRoutesConfig, 'AdminAdvisorAttributes', <AdvisorAttributes />);
setAppRouteComponent(appRoutesConfig, 'AdminAdvisorPayments', <AdminAdvisorPayments />);
setAppRouteComponent(appRoutesConfig, 'AdminAffiliatePayments', <AdminAffiliatePayments />);
setAppRouteComponent(appRoutesConfig, 'AdminDocuments', <AdminDocuments />);
setAppRouteComponent(appRoutesConfig, 'AdminIntakeForms', <AdminIntakeForms />);
setAppRouteComponent(appRoutesConfig, 'AdminReports', <AdminReports />);
setAppRouteComponent(appRoutesConfig, 'AdminThePlanImplementations', <AdminThePlanImplementations />);
setAppRouteComponent(appRoutesConfig, 'AdminFeatureFlags', <AdminFeatureFlags />);
setAppRouteComponent(appRoutesConfig, 'AdminReviews', <AdminReviews />);
setAppRouteComponent(appRoutesConfig, 'AdminUserAgreements', <AdminUserAgreements />);

setAppRouteComponent(appRoutesConfig, 'AdminEventsIndex', <AdminEvents />);
setAppRouteComponent(appRoutesConfig, 'AdminEditEvent', <AdminEditEvent />);

setAppRouteComponent(appRoutesConfig, 'AdminRecordingsIndex', <AdminRecordings />);
setAppRouteComponent(appRoutesConfig, 'AdminEditRecording', <AdminEditRecording />);

// Client Routes
setAppRouteComponent(
  appRoutesConfig,
  'ClientAccountRoot',
  <RoleRestriction allowedRoles={[UserRoles.Admin, UserRoles.Consumer].join(',')} element={<AccountLayout />} />
);
setAppRouteComponent(appRoutesConfig, 'ClientAccountIndex', <AccountIndex />);
setAppRouteComponent(appRoutesConfig, 'ClientAccountAffiliate', <ClientAccountAffiliate />);
setAppRouteComponent(appRoutesConfig, 'ClientAccountReviews', <ClientAccountReviews />);

// Advisor Routes
setAppRouteComponent(
  appRoutesConfig,
  'AdvisorAccountRoot',
  <RoleRestriction allowedRoles={[UserRoles.Admin, UserRoles.Advisor].join(',')} element={<AdvisorLayout />} />
);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountIndex', <AdvisorIndex />);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountClient', <AdvisorAccountClient />);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountComplianceReport', <AdvisorAccountComplianceReport />);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountPayments', <AdvisorAccountPayments />);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountProfile', <AdvisorAccountProfile />);
setAppRouteComponent(appRoutesConfig, 'AdvisorAccountReviews', <AdvisorAccountReviews />);

// Authentication Routes
setAppRouteComponent(appRoutesConfig, 'AuthenticationLogin', <Login action={UserLoginActions.Login} />);
setAppRouteComponent(appRoutesConfig, 'AuthenticationLoginFailed', <Login action={UserLoginActions.LoginFailed} />);
setAppRouteComponent(appRoutesConfig, 'AuthenticationLoginCallback', <Login action={UserLoginActions.LoginCallback} />);
setAppRouteComponent(appRoutesConfig, 'AuthenticationProfile', <Login action={UserLoginActions.Profile} />);

setAppRouteComponent(appRoutesConfig, 'AuthenticationLogout', <Logout action={UserLogoutActions.Logout} />);
setAppRouteComponent(appRoutesConfig, 'AuthenticationLogOutCallback', <Logout action={UserLogoutActions.LogoutCallback} />);
setAppRouteComponent(appRoutesConfig, 'AuthenticationLoggedOut', <Logout action={UserLogoutActions.LoggedOut} />);

const missingComponents = getMissingAppRouteComponents(appRoutesConfig);

if (missingComponents.length) {
  const errorMessage = `${missingComponents.length} route(s) were missing a component in AppRoutes.tsx`;
  console.error(errorMessage, missingComponents);
  throw new Error(errorMessage + '\n' + JSON.stringify(missingComponents));
}

const generateRoutesJSX = (routesToRender: AppRouteListing[]): ReactNode[] => {
  const returnValue: ReactNode[] = [];

  routesToRender.forEach((currRoute) => {
    const routeProps: RouteProps = {
      index: currRoute.isIndex,
      path: currRoute.path ?? undefined,
      element: currRoute.component ? (currRoute.component as ReactNode) : undefined
    };

    let childrenElements: ReactNode[] = [];
    if (currRoute.subRoutes?.length) {
      childrenElements = generateRoutesJSX(currRoute.subRoutes);
    }

    const newElement = React.createElement(
      Route,
      { ...routeProps, key: currRoute.name },
      childrenElements.length ? childrenElements : undefined
    );

    returnValue.push(newElement);
  });

  return returnValue;
};

const routesJSX = generateRoutesJSX(appRoutesConfig);

export default AppRoutes;
