import { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';

export const AdminLayout = () => {
  const [searchText, setSearchText] = useState<string>('');
  const navigate = useNavigate();

  const onSearch = () => {
    navigate(`/${ApplicationRoutes.AdminRoutes.Search_Full}?q=${encodeURIComponent(searchText)}`);
  };

  return (
    <Container>
      <div className="admin-menu">
        <Link to={`/${ApplicationRoutes.AdminRoutes.Root}`}> Admin Home</Link> |
        <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Root}`}> Account Home</Link> |
        <Link to={`/${ApplicationRoutes.AdvisorAccountRoutes.Root}`}> Advisor Home</Link>
        <div>
          <input
            className="admin-search"
            type="text"
            name="q"
            placeholder="Search name, email, ID, IP address, etc..."
            value={searchText}
            onChange={(e) => {
              setSearchText((e.target.value || '').trimStart());
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearch();
              }
            }}
          />
        </div>
      </div>
      <div></div>
      <Outlet />
    </Container>
  );
};
