import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import RecordingsApiService from '../../../../services/api/RecordingsApiService';
import DateHelper from '../../../Helpers/DateHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';

const AdminRecordings: React.FC = () => {
  const [recordings, setRecordings] = useState<Admin_MeetingRecording_ViewModel[] | null>(null);
  const [presignedLinksLoadingForRecodingIds, setPresignedLinksLoadingForRecodingIds] = useState<string[]>([]);
  const [recordingsAndPresignedS3Urls, setRecordingsAndPresignedS3Urls] = useState<{ [index: string]: string }>({});

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getAllRecordings();
      setRecordings(result);
    };

    loadData();
  }, []);

  const onDelete = async (e: React.MouseEvent, rec: Admin_MeetingRecording_ViewModel) => {
    e.preventDefault();

    const isConfirmed = window.confirm("Are you sure you want to delete this recording? This is permanent and can't be undone.");

    if (!isConfirmed) {
      return false;
    }

    const updatedRecordings = [...recordings!];
    const index = updatedRecordings.indexOf(rec);
    if (index !== -1) {
      updatedRecordings.splice(index, 1);
    }

    try {
      await AdminApiService.deleteRecording(rec.id);

      setRecordings(updatedRecordings);
      alert('Recording deleted successfully.');
    } catch (error) {
      alert(error);
      console.error('Unable to delete item.', error);
    }

    return false;
  };

  async function setS3LinksForRecording(recordingId: string): Promise<void> {
    try {
      setPresignedLinksLoadingForRecodingIds((v) => [...v, recordingId]);

      const presignedS3Url = await RecordingsApiService.getPresignedS3UrlForRecording(recordingId);

      setRecordingsAndPresignedS3Urls((v) => {
        return {
          ...v,
          [recordingId]: presignedS3Url
        };
      });
    } catch (error) {
      alert(error);
      console.error('Unable to get the S3 links for the recording.', error);
    } finally {
      setPresignedLinksLoadingForRecodingIds((v) => v.filter((m) => m !== recordingId));
    }
  }

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Recordings</title>
      </Helmet>
      <Row>
        <Col>
          <Link to={`/${ApplicationRoutes.AdminRoutes.Root}`}>
            <i className="fa-regular fa-arrow-left back-arrow"></i> Back
          </Link>
          <h1>View All Recordings</h1>

          {!recordings && <LoadingSpinner message="Loading..." />}
          {recordings && (
            <table className="table">
              <thead>
                <tr>
                  <th>Start Time</th>
                  <th>Duration</th>
                  <th>Event</th>
                  <th>Host Email</th>
                  <th>Size</th>
                  <th>Created At</th>
                  <th>Status</th>
                  <th>Links</th>
                  <th className="text-danger">Danger</th>
                </tr>
              </thead>
              <tbody>
                {recordings.map((rec) => (
                  <tr key={rec.id}>
                    <td>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Recordings_Full}/${rec.id}/edit`}>
                        {DateHelper.mediumDateFormat(rec.startTime)}
                      </Link>
                    </td>
                    <td>{rec.durationInMinutes} minutes</td>
                    <td>
                      {rec.eventId ? (
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Events_Full}/${rec.eventId}/edit`}>
                          {rec.advisorEmail} + {rec.consumerEmail}
                        </Link>
                      ) : (
                        <></>
                      )}

                      {!rec.eventId ? <strong className="red">No Linked Event!</strong> : <></>}
                    </td>
                    <td>{rec.hostEmail}</td>
                    <td>{(rec.fileSize / 1024.0 / 1024.0).toFixed(1)}MB</td>
                    <td>{DateHelper.mediumDateFormat(rec.createdAt)}</td>
                    <td>{rec.status}</td>
                    <td>
                      <div className="d-flex flex-nowrap align-items-center justify-content-center gap-2">
                        {recordingsAndPresignedS3Urls[rec.id] ? (
                          <div>
                            <a href={rec.downloadUrl} title="Download">
                              <i className="fa-regular fa-download"></i>
                            </a>
                            &nbsp;
                            <a className="mx-2" href={rec.playUrl} title="Play">
                              <i className="fa-regular fa-play"></i>
                            </a>
                            &nbsp;
                            <a href={recordingsAndPresignedS3Urls[rec.id]} title="S3">
                              <i className="fa-regular fa-database"></i>
                            </a>
                          </div>
                        ) : (
                          <Button color="primary text-nowrap" onClick={() => setS3LinksForRecording(rec.id)}>
                            {presignedLinksLoadingForRecodingIds.includes(rec.id) ? (
                              <i className="fa-regular fa-spinner isRotating mx-2"></i>
                            ) : (
                              <span>Get Links</span>
                            )}
                          </Button>
                        )}
                      </div>
                    </td>
                    <td>
                      <Button color="danger" onClick={(e) => onDelete(e, rec)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AdminRecordings;
