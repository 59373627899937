import AppRoutes from './AppRoutes';
import './styles/custom.scss';

const App: React.FC = () => {
  return <AppRoutes />;
};

export default App;

// This originally returned
//<Routes>
//    <Route path="/" element={<Layout />}>
//        {AppRoutes.map((route, index) => {
//            const { element, requireAuth, ...rest } = route;
//            return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
//        })}
//    </Route>
//</Routes>
