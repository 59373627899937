export default class GeneralHelper {
  public static isStringAGuid(input: string): boolean {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return guidRegex.test(input);
  }

  public static formatNumberAsCurrency(input: number): string {
    let returnValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2
    }).format(input);

    if (returnValue.endsWith('.00')) {
      returnValue = returnValue.substring(0, returnValue.length - 3);
    }

    return returnValue;
  }
}
