import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ApplicationRoutes } from '../../../constants';
import AffiliatesApiService from '../../../services/api/AffiliatesApiService';
import LoadingSpinner from '../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import { LandingPageTemplate } from './LandingPageTemplate';

const AffiliateLandingPage: React.FC = () => {
  // Get the slug from the URL
  const navigate = useNavigate();
  const { slug } = useParams();
  const [affiliate, setAffiliate] = useState<User_BasicDetails_ViewModel | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await AffiliatesApiService.getAffiliateInfo(slug!);
        setAffiliate(result);

        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
        Cookies.set('klpxqgwlwpao', result.id, { expires: 30 });
      } catch {
        // NOTE: The error is logged in the code that makes the API call
        navigate('/');
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Helmet>
        {/* Give the SEO juice from this page to our home page */}
        <link rel="canonical" href={ApplicationRoutes.Root} />
      </Helmet>
      {!affiliate && <LoadingSpinner />}
      {affiliate && (
        <LandingPageTemplate
          plural="financial advisors"
          singular="advisor"
          disclaimer={
            affiliate.fullName +
            ' is not an employee of Nectarine. By using this link, ' +
            affiliate.fullName +
            ' will be compensated and therefore has an incentive to endorse Nectarine. All opinions are their own and may not express the views of Nectarine, nor are they a guarantee of a similar outcome. ' +
            affiliate.fullName +
            ' will receive a 10% compensation of all of your purchases for the first year of you signing up.'
          }
        />
      )}
    </Container>
  );
};

export default AffiliateLandingPage;
