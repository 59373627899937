//@ts-check
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Col, Container, Form, Input, Row } from 'reactstrap';
import { AppSettings } from '../../../../constants';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import HttpHelper from '../../../Helpers/HttpHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';

async function populateData() {
  const data = await HttpHelper.get('api/files');
  // console.log('Data:', data);
  return data;
}

export const AdminDocuments = () => {
  const [documents, setDocuments] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  React.useEffect(() => {
    populateData().then((a) => {
      setDocuments(a);
    });
  }, []);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    // console.log('SelectedFile!', selectedFile);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();

    if (selectedFile) {
      // console.log('handleFileUpload - SelectedFile!', selectedFile);

      const formData = new FormData();

      // console.log('handleFileUpload formData', formData);

      var displayName = event.target.elements['displayName'].value;
      const params = new URLSearchParams();
      params.append('displayName', displayName);

      var url = '/api/files/compliancedocument?' + params.toString();

      // console.log('url', url);
      formData.append('file', selectedFile);

      const token = await AuthorizeService.getAccessToken();

      fetch(url, {
        method: 'POST',
        body: formData,
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
        // .then((response) => {
        // console.log('handleFileUpload response', response);
        // })
        .then(() => {
          // console.log('handleFileUpload data', data);
          // Handle response data or success message
          populateData().then((a) => {
            setDocuments(a);
          });
        })
        .catch((error) => {
          console.error('Error:', error);
          // Handle error
        });
    }
  };

  const onSetDocumentBoolean = async (event, docID, isSet, attributeName) => {
    event.preventDefault();

    // console.log('onSetDocumentActive', docID, isSet);

    //setUser(null);

    HttpHelper.put('/api/files/compliancedocument/set' + attributeName + '/' + docID, isSet)
      .then(() => {
        // console.log('Set Active response', response);
      })
      .then(() => {
        // console.log('End onSetDocumentInactive');
        populateData().then((a) => {
          setDocuments(a);
        });
      })
      .catch((error) => {
        alert('Unable to set document active.', error);
        populateData().then((a) => {
          setDocuments(a);
        });
      });
  };

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Compliance Documents</title>
      </Helmet>
      <Row>
        <Col>
          <h1>{AppSettings.ApplicationName} Compliance Documents</h1>
          <p>
            View and upload the Nectarine compliance documents that users agree to when they click to agree. Only active (✅) documents will
            be displayed to users and logged when they click to agree. But all documents are stored permanently as linked below.
          </p>

          {!documents && <LoadingSpinner message="Loading..." />}
          {documents && (
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Document</th>
                    <th>Original Name</th>
                    <th>{AppSettings.ApplicationName} Hour</th>
                    <th>{AppSettings.ApplicationName} Plan</th>
                    <th>Promoters</th>
                    <th>Affiliates</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(documents)
                    .sort((a, b) => documents[b]['timestamp'].localeCompare(documents[a]['timestamp']))
                    .map((i) => (
                      <tr key={i}>
                        <td>{new Date(documents[i]['timestamp']).toLocaleDateString()}</td>
                        <td>
                          <a href={documents[i]['url']} target="_blank" rel="noopener noreferrer">
                            {documents[i]['displayName']}
                          </a>
                        </td>
                        <td>{documents[i]['originalFileName']}</td>
                        <td>
                          <a
                            href="#"
                            onClick={(e) =>
                              onSetDocumentBoolean(e, documents[i].id, !documents[i].showToTheHourBuyers, 'showtothehourbuyers')
                            }
                          >
                            {documents[i].showToTheHourBuyers ? '✅' : '🔲'}
                          </a>
                        </td>
                        <td>
                          <a
                            href="#"
                            onClick={(e) =>
                              onSetDocumentBoolean(e, documents[i].id, !documents[i].showToThePlanBuyers, 'showtotheplanbuyers')
                            }
                          >
                            {documents[i].showToThePlanBuyers ? '✅' : '🔲'}
                          </a>
                        </td>
                        <td>
                          <a
                            href="#"
                            onClick={(e) => onSetDocumentBoolean(e, documents[i].id, !documents[i].showToPromoters, 'showtopromoters')}
                          >
                            {documents[i].showToPromoters ? '✅' : '🔲'}
                          </a>
                        </td>
                        <td>
                          <a
                            href="#"
                            onClick={(e) => onSetDocumentBoolean(e, documents[i].id, !documents[i].showToAffiliates, 'showtoaffiliates')}
                          >
                            {documents[i].showToAffiliates ? '✅' : '🔲'}
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <h3>Upload A New Document</h3>
              <div>
                <Form onSubmit={handleFileUpload}>
                  <Input id="displayName" name="displayName" type="select">
                    <option value="">Choose a contract...</option>
                    <option key="Terms and Conditions" value="Terms and Conditions">
                      Terms and Conditions
                    </option>
                    <option key="Contract" value="Contract">
                      Contract
                    </option>
                    <option key="ADV Part 2" value="ADV Part 2">
                      ADV Part 2
                    </option>
                    <option key="ADV Part 2A" value="ADV Part 2A">
                      ADV Part 2A
                    </option>
                    <option key="Privacy Policy" value="Privacy Policy">
                      Privacy Policy
                    </option>
                    <option key="Form CRS" value="Form CRS">
                      Form CRS
                    </option>
                    <option key="Promoter Disclosure" value="Promoter Disclosure">
                      Promoter Disclosure
                    </option>
                    <option key="Affiliate Agreement and Disclosure" value="Affiliate Agreement and Disclosure">
                      Affiliate Agreement and Disclosure
                    </option>
                  </Input>
                  <Input type="file" onChange={handleFileSelect} />
                  <Button color="primary">Upload</Button>
                </Form>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
