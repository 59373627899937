import { useEffect, useState } from 'react';
import { Alert, Button, Form, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import LoadingDots from '../../../../ui/LoadingAnimations/LoadingDots/LoadingDots';

interface AdvisorEditRateProps {
  rateType: 'hourly' | 'the-plan';
  advisor: Advisor_BasicDetails_ViewModel;
  onSave: (updatedAdvisorVM: Advisor_BasicDetails_ViewModel) => void;
}

const AdvisorEditRate: React.FC<AdvisorEditRateProps> = (props: AdvisorEditRateProps) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [advisorToEdit, setAdvisorToEdit] = useState<Advisor_EditAdvisor_EditModel | null>(null);
  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });
  const [rateChangedAlert, setRateChangedAlert] = useState<boolean>(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const toggleModal = () => setShowEditModal(!showEditModal);

  useEffect(() => {
    setAdvisorToEdit({
      bio: '',
      calendarLink: '',
      certifications: '',
      experienceStart: props.advisor.experienceStart,
      firstName: '',
      hourlyRate: props.advisor.hourlyRate,
      thePlanRate: props.advisor.thePlanRate,
      id: props.advisor.id,
      lastName: '',
      longBio: props.advisor.longBio,
      personalBio: props.advisor.personalBio,
      rightCapitalLink: '',
      rightCapitalLogin: '',
      showReviews: false,
      title: ''
    });
  }, [props.advisor]);

  function onSetRate(e: React.ChangeEvent<HTMLInputElement>, propertyName: keyof Advisor_EditAdvisor_EditModel) {
    hideError();

    const newRate = e.target.value;
    // Ensure the input is a valid dollar amount
    if (/^\d*\.?\d{0,2}$/.test(newRate)) {
      setAdvisorToEdit((v) => ({
        ...v!,
        [propertyName]: e.target.value
      }));
    } else {
      showError('Please enter a valid dollar amount.');
    }
  }

  const onSave = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setSaveLoading(true);

      await AdvisorsApiService.updateAdvisorBioOrRates(advisorToEdit!);

      const updatedAdvisorVM: Advisor_BasicDetails_ViewModel = {
        ...props.advisor,
        hourlyRate: advisorToEdit!.hourlyRate,
        thePlanRate: advisorToEdit!.thePlanRate
      };

      props.onSave(updatedAdvisorVM);

      setShowEditModal(false);

      if (props.rateType === 'hourly') {
        setRateChangedAlert(true);
      }
    } catch (error: any) {
      showError(error.message);
    } finally {
      setSaveLoading(false);
    }

    return false;
  };

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function hideError() {
    setErrorSection({
      show: false,
      text: null
    });
  }

  function getRateDisplayName(): string {
    return props.rateType === 'hourly' ? 'Hourly' : 'The Plan';
  }

  return (
    <div>
      {props.rateType === 'hourly' ? (
        <>
          {props.advisor.hourlyRate > 0 ? (
            <div>
              <span className="rateLabel">${Math.round(props.advisor.hourlyRate).toLocaleString('en-US')}</span>
              <span className="results-product"> / one hour</span>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {props.advisor.thePlanRate > 0 ? (
            <div>
              <span className="rateLabel">${Math.round(props.advisor.thePlanRate).toLocaleString('en-US')}</span>
              <span className="results-product"> / 8 weeks</span>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {props.rateType === 'hourly' && !props.advisor.hourlyRate ? <p>Not Set - Click Edit Below</p> : <></>}

      {props.rateType === 'the-plan' && !props.advisor.thePlanRate ? <p>Not Set - Click Edit Below</p> : <></>}

      <Alert color="danger" isOpen={rateChangedAlert} toggle={() => setRateChangedAlert(false)}>
        <p>
          <strong>Important! Action Required!</strong> Changes here <strong>do not automatically</strong> update the amount charged by
          Calendly. To complete this change, please visit your{' '}
          <a href="https://calendly.com/event_types/user/me" target="_blank" rel="noopener noreferrer">
            Calendly Event types page
          </a>{' '}
          then:
        </p>
        <ol>
          <li>Click on the Nectarine Advisor Meeting event</li>
          <li>Click "Booking page options" on the left</li>
          <li>Edit the "Amount to be collected" to match the number shown above</li>
          <li>Click "Save and close"</li>
        </ol>
        <p>
          Failing to do this will result in clients paying a different amount than what they agreed to in their contract and what is
          advertised on the site.
        </p>
      </Alert>
      <p>
        <Button color="primary" onClick={toggleModal}>
          Edit {getRateDisplayName()} Rate
        </Button>
      </p>

      <Modal isOpen={showEditModal} toggle={toggleModal} className="rate-dialog">
        <Form>
          <ModalHeader toggle={toggleModal}>Edit {getRateDisplayName()} Rate</ModalHeader>
          <ModalBody>
            {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}
            <Label>{getRateDisplayName()} Rate</Label>
            <InputGroup className="editHourlyRateContainer">
              <InputGroupText>$</InputGroupText>
              <Input
                type="number"
                value={props.rateType === 'hourly' ? advisorToEdit?.hourlyRate : advisorToEdit?.thePlanRate}
                onChange={(e) => {
                  onSetRate(e, props.rateType === 'hourly' ? 'hourlyRate' : 'thePlanRate');
                }}
              />
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={saveLoading} onClick={onSave}>
              {saveLoading ? <LoadingDots /> : `Save ${getRateDisplayName()} Rate`}
            </Button>{' '}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AdvisorEditRate;
