import { ReactElement, useRef, useState } from 'react';
import { FeatureFlags, UserRoles } from '../../../../constants';
import { useGeneralContext } from '../../../../services/GeneralContext';
import './Snow.scss';

interface SnowProps {
  children: ReactElement;
}

const Snow: React.FC<SnowProps> = (props: SnowProps) => {
  const [isSnowing, setIsSnowing] = useState<boolean>(false);
  const hasSnowed = useRef<boolean>(false);

  const { checkUserRole } = useGeneralContext();
  const userIsAdmin = checkUserRole(UserRoles.Admin);

  const { checkFeatureFlag } = useGeneralContext();
  const showWinterEasterEggs = checkFeatureFlag(FeatureFlags.WinterEasterEggs) || userIsAdmin;

  const makeItSnow = () => {
    if (!showWinterEasterEggs) {
      return;
    }

    if (!isSnowing && !hasSnowed.current) {
      setIsSnowing(true);
      hasSnowed.current = true;

      setTimeout(() => {
        setIsSnowing(false);
      }, 45_000);
    }
  };

  return userIsAdmin ? (
    <div
      className="snowStorm"
      onMouseOver={() => {
        makeItSnow();
      }}
    >
      {isSnowing ? (
        <div
          className="snowBank"
          onMouseOver={() => {
            makeItSnow();
          }}
        >
          {Array.from(Array(25)).map((v, i) => {
            return (
              <div key={i} className="snowflake" style={{ left: `${Math.random() * 100}%`, top: `${Math.random() * 20}px` }}>
                <i className="fa-duotone fa-regular fa-snowflake c-blue"></i>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}

      <div className="childrenContainer">{props.children}</div>
    </div>
  ) : (
    <div className="childrenContainer">{props.children}</div>
  );
};

export default Snow;
