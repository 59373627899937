import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdvisorFilterSortOptions } from '../components/Pages/Advisors/AdvisorResults/AdvisorsResults';
import { ApplicationRoutes } from '../constants';
import useUrlHelper from './UseUrlHelper';

interface UseNavigationService {
  navigateTo_AdvisorsResults: (sort?: AdvisorFilterSortOptions, filters?: string[]) => void;
  navigateTo_AdvisorsDetails: (advisorId: string) => void;
  navigateTo_Error: () => void;
  navigateTo_ThePlanTerms: (advisorId: string) => void;
}

const useNavigationService = (): UseNavigationService => {
  const navigate = useNavigate();
  const { getQueryStringParameter } = useUrlHelper();

  const navigateTo_AdvisorsResults = (sort?: AdvisorFilterSortOptions | null, filters?: string[]): void => {
    // Update the URL to reflect the selected attributes, like ?filters=real-estate+retirement
    sort = sort ?? getQueryStringParameter<AdvisorFilterSortOptions>('sort') ?? 'available';

    // If it's the default sort option, we don't show it in the URL
    if (sort === 'available') {
      sort = null;
    }

    let newUrl = '';

    if (filters?.length === 1) {
      newUrl += `/${filters[0]}`;

      if (sort) {
        newUrl += `?sort=${sort}`;
      }
    } else if ((filters?.length ?? 0) > 0) {
      newUrl += `?filters=${filters!.join('+')}`;
      if (sort) {
        newUrl += `&sort=${sort}`;
      }
    }

    navigate(`/${ApplicationRoutes.Advisors}${newUrl}`);
  };

  const navigateTo_AdvisorsDetails = useCallback(
    (advisorSlug: string): void => {
      navigate(`/${ApplicationRoutes.Advisor}/${advisorSlug}`);
    },
    [navigate]
  );

  const navigateTo_Error = useCallback((): void => {
    navigate(`/${ApplicationRoutes.Error}`);
  }, [navigate]);

  const navigateTo_ThePlanTerms = useCallback(
    (advisorSlug: string): void => {
      navigate(`/${ApplicationRoutes.ThePlan_Terms}/${advisorSlug}`);
    },
    [navigate]
  );

  return {
    navigateTo_AdvisorsResults,
    navigateTo_AdvisorsDetails,
    navigateTo_Error,
    navigateTo_ThePlanTerms
  };
};

export default useNavigationService;
